import { createContext, createRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useState } from 'react';

const AuthContext = createContext();

const tokenRef = createRef();
const csrfRef = createRef();

export const AuthProvider = ({ authService, authErrorEventBus, children }) => { 
    const [user, setUser] = useState(undefined);
    const [csrfToken, setCsrfToken] = useState(undefined);

    useImperativeHandle(tokenRef, () => (user ? user.token : undefined));
    useImperativeHandle(csrfRef, () => csrfToken);

    useEffect(() => {
        authErrorEventBus.listen((err) => {
            console.log(err);
            setUser(undefined);
        });
    }, [authErrorEventBus]);
    
    useEffect(() => {

        // 1. url에서 token을 가져온다. 급해서 이렇게 처리함.
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');

        if (token) {
            authService.loginWithToken({token: token}).then(setUser).catch(console.error);
        }

        authService.csrfToken().then(setCsrfToken).catch(console.error);
    }, [authService]);

    useEffect(() => {
        authService.me().then(setUser).catch(console.error);
    }, [authService]);

    const signUp = useCallback(async (username, password, name, email, url) =>
        authService
            .signup(username, password, name, email, url)
            .then((user) => setUser(user)),
        [authService] // authService가 바뀌면 다시 실행
    );

    const login = useCallback(
        async (username, password) =>
            authService.login(username, password).then((user) => setUser(user)),
        [authService]
    );

    const logout = useCallback(
        async () => authService.logout().then(() => setUser(undefined)),
        [authService]
    );

    const context = useMemo(() => ({ user, signUp, login, logout }), [user, signUp, login, logout]);

    return <AuthContext.Provider value={context}>
    { user ? 
        ( children ) : 
        (
            children
            // <div className='app'>
            //     로그인 화면
            //     {/* <Login /> */}
            // </div>
    )}</AuthContext.Provider>;
}

export class AuthErrorEventBus {
    listen(callback) {
        this.callback = callback;
    }
    notify(error) {
        this.callback(error);
    }
}

export const fetchToken = () => tokenRef.current;
export const fetchCsrfToken = () => csrfRef.current;
export const useAuth = () => useContext(AuthContext);
