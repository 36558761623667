import React from 'react';

import WatchWord from './WatchWord';
import SideMenuList from './SideMenuList';
import Vision from './Vision';

interface OwnProps {}

const SideMenuPage = () => {
  return (
    <div>
      <WatchWord />
      <SideMenuList />
      <Vision />
    </div>
  );
};

export default SideMenuPage;
