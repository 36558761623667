export default class AuthService {
    constructor(http) {
      this.http = http;
    }

    async csrfToken() {
      const resp = await this.http.get('/auth/csrf-token');
      return resp.csrfToken;
    }

    async me() {
      return this.http.get('/auth/me');
    }

    async loginWithToken(token) {
      return this.http.post('/auth/login/token', { token })
    }
  }
  