import { Box } from '@mui/material';
import { useState } from 'react';

import SekcMap from '../SekcMap';

import { useRecoilState } from 'recoil';

import { useMousePosition } from '../../../libs/hooks/map';
import { prayerDataAtom } from '../../../libs/states/prayer';

const colors = ['#1f1f1f', '#32455C', '#406287', '#4D80B8', '#42a5f5'];

const ChurchMap = () => {
  const [hoveringRegion, setHoveringRegion] = useState('');
  const [isHovering, setIsHovering] = useState(false);
  const { x, y } = useMousePosition();

  const [prayerData, setPrayerData] = useRecoilState(prayerDataAtom);

  const handleMouseEnter = (region: string) => {
    setIsHovering(true);
    setHoveringRegion(region);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
    setHoveringRegion('');
  };

  const handleClick = (region: string) => {
    // if (isOpenRegion) return setIsOpenRegion(false);
    // setOpenRegion(region);
    // setIsOpenRegion(true);
  };

  const regionStyle = (region: string, transformOrigin: string, randomNumber: number) => {
    return {
      // filter: hoveringRegion && hoveringRegion !== region ? 'blur(3px)' : '', //
      // transition: 'filter 0.5s, fill 0.5s, opacity 0.5s, transform 0.5s',
      stroke: '',
      fill: colors[randomNumber],
      // fill: '#1f1f1f',
      // opacity: hoveringRegion && hoveringRegion !== region ? '0.5' : '1',
      // transform: hoveringRegion === region ? 'scale(1.1)' : '',
      // transformOrigin: transformOrigin,
      curser: 'none',
      // zIndex: hoveringRegion === region ? 1 : 0,
    };
  };

  return (
    <Box>
      <Box top={5} sx={{ position: 'reletive', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {/* {isHovering && <RegionCard x={x} y={y} hoveringRegion={hoveringRegion} regionData={prayerData} setRegionData={setPrayerData} />} */}
        <SekcMap handleMouseEnter={handleMouseEnter} handleMouseLeave={handleMouseLeave} regionStyle={regionStyle} handleClick={handleClick} />
      </Box>
    </Box>
  );
};

export default ChurchMap;
