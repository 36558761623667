import { atom } from 'recoil';

export const anchorAtom = atom({
    key: 'anchorEl',
    default: null,
});

export const regionColorAtom = atom({
    key: 'regionColor',
    default: null,
});

export const selectedChurchAtom = atom({
    key: 'selectedChurch',
    default: '',
});
export const selectedChurchIdAtom = atom({
    key: 'selectedChurchId',
    default: '',
});

export const selectedChurchesAtom = atom({
    key: 'selectedChurches',
    default: [],
});

export const baptismAtom = atom({
    key: 'baptism',
    default: [],
});

export const selectedChurchIdsAtom = atom({
    key: 'selectedChurchIds',
    default: [],
});

export const churchPopoverOpenAtom = atom({
    key: 'churchPopoverOpen',
    default: false,
});

export const churchModalOpenAtom = atom({
    key: 'churchModalOpen',
    default: false,
});

export const churchGroupMenuOpenAtom = atom({
    key: 'churchGroupMenuOpen',
    default: false,
});

export const filteredChurchNameAtom = atom({
    key: 'filteredChurchName',
    default: '',
});

export const isSearchingAtom = atom({
    key: 'isSearching',
    default: false,
});

export const churchReportDataAtom = atom({
    key: 'churchReportData',
    default: [],
});

export const allReportDataAtom = atom({
    key: 'allReportData',
    default: [],
});
export const sumReportDataAtom = atom({
    key: 'sumReportData',
    default: [],
});
