import { Card } from '@mui/material';
import { grey, orange } from '@mui/material/colors';

import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { chartStyle } from '../../../../libs/theme/chart';

const AttendanceAge = () => {
  const chartSeries: ApexOptions['series'] = [
    {
      name: '1 - 13세',
      type: 'bar',
      data: [689, 700, 711, 722, 733],
    },
    {
      name: '14 - 19세',
      type: 'bar',
      data: [442, 450, 460, 470, 480],
    },
    {
      name: '20 - 40세',
      type: 'bar',
      data: [940, 950, 960, 970, 980],
    },
    {
      name: '41 - 60세',
      type: 'bar',
      data: [1757, 1768, 1779, 1790, 1801],
    },
    {
      name: '61세 이상',
      type: 'bar',
      data: [2631, 2642, 2653, 2664, 2675],
    },
  ];

  const chartOptions: ApexOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      stackType: '100%',
      toolbar: { show: false },
      zoom: { enabled: false },
    },
    stroke: {
      curve: 'smooth',
      width: [0, 0, 0, 0, 0],
    },
    xaxis: {
      categories: ['2023년 1기', '2기', '3기', '4기', '2024년 1기'],

      labels: { ...chartStyle.labels },
    },
    yaxis: [
      {
        labels: { ...chartStyle.labels },
        show: false,
      },
    ],

    legend: {
      position: 'top',
      offsetY: 0,
      ...chartStyle.legend,
    },
    title: {
      text: '출석 연령비',
      style: {
        ...chartStyle.title,
      },
    },

    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 3,
        borderRadiusApplication: 'end',
      },
    },
    dataLabels: {
      style: {
        fontSize: '10',
        fontFamily: 'SCoreDream',
        colors: [grey[300], grey[300], grey[800], grey[800], grey[800]],
      },
    },
    tooltip: {
      theme: 'dark',
    },
    colors: [orange[900], orange[700], orange[500], orange[300], orange[200]],
    grid: { ...chartStyle.grid },
  };

  return (
    <Card sx={{ backgroundColor: grey[900], p: 2, mb: 2 }}>
      <ReactApexChart options={chartOptions} series={chartSeries} type="bar" width="100%" height={241} />
    </Card>
  );
};

export default AttendanceAge;
