import React from 'react';
import AppBar from '@mui/material/AppBar';

import Box from '@mui/material/Box';

import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

const Footer = () => {
    return (
        <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
            <Toolbar sx={{ backgroundColor: '#1c1c1c' }} variant="dense">
                <Typography fontFamily="SCoreDream" color={grey[700]} fontWeight={200} fontSize={12}>
                    ※ 본 데이터는 영남합회 2023년도 사업계획서에 명시된 사항을 기반으로 제작되었습니다.
                </Typography>
                <Box sx={{ flexGrow: 1 }} />

                <IconButton color="inherit" component={Link} to="/">
                    <span className="material-icons" style={{ fontSize: '0.7em' }}>
                        logout
                    </span>
                </IconButton>
            </Toolbar>
        </AppBar>
    );
};

export default Footer;
