import { Box } from '@mui/material';

import { CarryOutAndActivate, PrayerAndUser, PrayerResponse } from './charts';

const DigitalSidePage = () => {
  return (
    <Box>
      <PrayerAndUser />
      <PrayerResponse />
      <CarryOutAndActivate />
    </Box>
  );
};

export default DigitalSidePage;
