import { grey } from '@mui/material/colors';

const TITLE = {
    fontSize: '12px',
    fontWeight: 200,
    fontFamily: 'SCoreDream',
    color: grey[500],
};
const LEGEND = {
    fontSize: '10px',
    fontFamily: 'SCoreDream',
    fontWeight: 200,
    labels: {
        colors: grey[500],
    },
};

const LABELS = {
    style: {
        fontSize: '10px',
        fontFamily: 'SCoreDream',
        fontWeight: 200,
        colors: grey[500],
    },
};
const TOTAL = {
    fontSize: '10px',
    fontFamily: 'SCoreDream',
    fontWeight: 200,
    colors: grey[500],
};

const GRID = {
    borderColor: grey[800],
    strokeDashArray: 4,
};
export const chartStyle = {
    title: { ...TITLE },
    legend: { ...LEGEND },
    labels: { ...LABELS },
    total: { ...TOTAL },
    grid: { ...GRID },
};
