import { Box } from '@mui/material';

import { Growth, Settlement, SmallGroup } from './charts';

const ChurchSidePage = () => {
  return (
    <Box>
      <SmallGroup />
      <Growth />
      <Settlement />
    </Box>
  );
};

export default ChurchSidePage;
