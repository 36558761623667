import React from 'react';
import { Box, Grid } from '@mui/material';

import ChurchSearch from './ChurchSearch';

import CurrentWorship from './CurrentWorship';

import WorshipMap from './WorshipMap';
import WorshipSidePage from './WorshipSidePage';

import { TITLE_BOX_HEIGHT } from '../../../libs/config/constants';

type OwnProps = {};

const WorshipPage: React.FC<OwnProps> = () => {
  return (
    <Box>
      <Grid container>
        {/* <Grid container item xs={12} md={12} lg={8}> */}
        <Grid item xs={12} md={12} lg={3}>
          <Box mt={TITLE_BOX_HEIGHT + 5}>
            <CurrentWorship />
          </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Box ml={3} sx={{ position: 'relative' }}>
            <ChurchSearch />
            <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
              <WorshipMap />
            </Box>
            {/* <Legend /> */}
          </Box>
          {/* </Grid> */}
        </Grid>
        <Grid item xs={12} md={12} lg={3}>
          <WorshipSidePage />
        </Grid>
      </Grid>
    </Box>
  );
};

export default WorshipPage;
