import React from 'react';

import { Autocomplete, Box, Button, TextField } from '@mui/material';

import { useRecoilState } from 'recoil';
import { filteredChurchNameAtom, isSearchingAtom } from '../../../libs/states/church/church';
import { locations } from '../../../libs/map/locationData';

const ChurchSearch = () => {
  const [filteredChurchName, setFilteredChurchName] = useRecoilState(filteredChurchNameAtom);
  const [isSearching, setIsSearching] = useRecoilState(isSearchingAtom);

  const churches = locations
    .map((churchGroup) => churchGroup.churches)
    .reduce((acc, val) => acc.concat(val), [])
    .sort((a, b) => a.localeCompare(b));

  const handleOnChange = (e, newName) => {
    setFilteredChurchName(newName);
  };

  const handleSearchingButton = () => {
    setIsSearching(!isSearching);
    isSearching && setFilteredChurchName('');
  };

  return (
    <Box display="flex">
      <Button onClick={handleSearchingButton} title="검색" sx={{ width: '15%', height: 56, mr: 1 }}>
        검색
      </Button>
      {isSearching && (
        <Autocomplete
          value={filteredChurchName ? filteredChurchName : ''}
          onChange={handleOnChange}
          inputValue={filteredChurchName ? filteredChurchName : ''}
          onInputChange={handleOnChange}
          options={churches}
          renderInput={(params) => <TextField {...params} label="교회를 검색하세요" variant="outlined" />}
          sx={{ pr: 4, width: '80%' }}
        />
      )}
    </Box>
  );
};

export default ChurchSearch;
