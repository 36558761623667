import React from 'react';
import { useRecoilValue } from 'recoil';

import { selectedMenuState } from '../../libs/states/common';

import { Box } from '@mui/material';
import { ChurchPage, DigitalPage, MinistryPage, PublicDataPage, ReliefPage, WorshipPage } from '.';
import TitleBox from '../modules/TitleBox';

interface OwnProps {}

const MainSection: React.FC<OwnProps> = () => {
  const selectedMenu = useRecoilValue(selectedMenuState);

  let MainComponent;

  switch (selectedMenu.title) {
    case '예배현황':
      MainComponent = WorshipPage;
      break;

    case '교회현황':
      MainComponent = ChurchPage;
      break;

    case '디지털선교현황':
      MainComponent = DigitalPage;
      break;

    case '사업현황':
      MainComponent = MinistryPage;
      break;

    case '지역사회현황':
      MainComponent = PublicDataPage;
      break;

    case '구호현황':
      MainComponent = ReliefPage;
      break;

    default:
      MainComponent = WorshipPage;
  }

  return (
    <Box p={3} width={1}>
      <TitleBox />
      <MainComponent />
    </Box>
  );
};

export default MainSection;
