import { Avatar, Box, Card, CardContent, Divider, Stack, Typography } from '@mui/material';
import { blue, grey, red } from '@mui/material/colors';

import styled from '@emotion/styled';
import CountUp from 'react-countup';

const StyledLargeNumber = styled(Typography)`
  font-family: '강조체';
  text-align: center;
  color: #ccc;
`;
const StyledSmallText = styled(Typography)`
  font-family: 'SCoreDream';
  font-weight: 300;
  font-size: 0.8rem;
  text-align: center;
  color: #555;
`;
const UpText = styled(Typography)`
  font-family: 'SCoreDream';
  font-size: 12px;
  color: ${red[700]};
  /* opacity: 0.7; */
`;
const DownText = styled(Typography)`
  font-family: 'SCoreDream';
  font-size: 12px;
  color: ${blue[700]};
  /* opacity: 0.7; */
`;

const GreyDivider = styled(Divider)`
  color: ${grey[700]};
`;

const DigitalStatus = () => {
  return (
    <Box
      mb={3}
      sx={{
        overflow: 'auto',
        width: '100%',
        maxHeight: 898,
      }}
    >
      <Stack spacing={3}>
        <Box width="100%" display="flex" alignItems="center" justifyContent="center" py={2}>
          <Avatar src="/common/img/icons/prayer.png" variant="square" sx={{ width: 60, height: 60, mr: 2 }} />
          <Box>
            <StyledLargeNumber variant="h3">
              <CountUp end={5433} separator="," />
            </StyledLargeNumber>
            <StyledSmallText>기도 요청</StyledSmallText>
          </Box>
        </Box>
        <Card>
          <CardContent sx={{ display: 'flex', justifyContent: 'center', pb: 2 }}>
            <Box width="100%">
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'end', mr: 1 }}>
                  <StyledLargeNumber variant="h3" mr={1}>
                    <CountUp end={50} separator="," />
                  </StyledLargeNumber>
                  <StyledSmallText pb={0.5}>%</StyledSmallText>
                </Box>
                <DownText>▼ 1%</DownText>
              </Box>
              <StyledSmallText>기도 응답률</StyledSmallText>
            </Box>
          </CardContent>
          <GreyDivider variant="middle" />
          <CardContent sx={{ display: 'flex', justifyContent: 'center', pt: 2.5 }}>
            <Stack width="100%" direction="row" spacing={2} divider={<GreyDivider orientation="vertical" flexItem />}>
              <Box width="100%">
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'end', mr: 1 }}>
                    <StyledLargeNumber variant="h4">
                      <CountUp end={394} separator="," />
                    </StyledLargeNumber>
                  </Box>
                  <UpText>▲ 13</UpText>
                </Box>
                <StyledSmallText>기도 응답</StyledSmallText>
              </Box>
              <Box width="100%">
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'end', mr: 1 }}>
                    <StyledLargeNumber variant="h4">
                      <CountUp end={394} separator="," />
                    </StyledLargeNumber>
                  </Box>
                  <DownText>▲ 43</DownText>
                </Box>
                <StyledSmallText>미응답 기도</StyledSmallText>
              </Box>
            </Stack>
          </CardContent>
        </Card>
        <Stack width="100%" direction="row" py={1}>
          <Box width="100%" display="flex" alignItems="center">
            <Avatar src="/common/img/icons/user.png" variant="square" sx={{ width: 50, height: 50, mr: 1 }} />
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%">
              <StyledLargeNumber variant="h4">
                <CountUp end={394} separator="," />
              </StyledLargeNumber>
              <StyledSmallText>숨친 가입자</StyledSmallText>
            </Box>
          </Box>
          <Box width="100%" display="flex" alignItems="center">
            <Avatar src="/common/img/icons/church.png" variant="square" sx={{ width: 50, height: 50, mr: 1 }} />
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%">
              <StyledLargeNumber variant="h4">
                <CountUp end={4} separator="," />
              </StyledLargeNumber>
              <StyledSmallText>숨친 교회</StyledSmallText>
            </Box>
          </Box>
        </Stack>
        <Stack width="100%" direction="row" py={1}>
          <Box width="100%" display="flex" alignItems="center">
            <Avatar src="/common/img/icons/leader.png" variant="square" sx={{ width: 50, height: 50, mr: 1 }} />
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%">
              <StyledLargeNumber variant="h4">
                <CountUp end={34} separator="," />
              </StyledLargeNumber>
              <StyledSmallText>숨친 리더</StyledSmallText>
            </Box>
          </Box>
          <Box width="100%" display="flex" alignItems="center">
            <Avatar src="/common/img/icons/missionary.png" variant="square" sx={{ width: 50, height: 50, mr: 1 }} />
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%">
              <StyledLargeNumber variant="h4">
                <CountUp end={233} separator="," />
              </StyledLargeNumber>
              <StyledSmallText>숨친 선교사</StyledSmallText>
            </Box>
          </Box>
        </Stack>
        <Card>
          <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
            <Stack width="100%" direction="row" spacing={2} divider={<GreyDivider orientation="vertical" flexItem />}>
              <Box width="100%">
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'end' }}>
                  <StyledLargeNumber variant="h4" mr={1}>
                    <CountUp end={15} separator="," />
                  </StyledLargeNumber>
                  <StyledSmallText pb={0.5}>%</StyledSmallText>
                </Box>
                <StyledSmallText>숨 활성화도</StyledSmallText>
              </Box>
              <Box width="100%">
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'end' }}>
                  <StyledLargeNumber variant="h4" mr={1}>
                    <CountUp end={13} separator="," />
                  </StyledLargeNumber>
                  <StyledSmallText pb={0.5}>%</StyledSmallText>
                </Box>
                <StyledSmallText>숨친 프로젝트 시행률</StyledSmallText>
              </Box>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </Box>
  );
};

export default DigitalStatus;
