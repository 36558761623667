import { Avatar, Box, Card, CardContent, Divider, Grid, Skeleton, Stack, Typography } from '@mui/material';

import CountUp from 'react-countup';

import { useRecoilValue } from 'recoil';
import { selectedMenuState } from '../../../libs/states/common';

import { pastors } from '../../../libs/data/pastors';
import styled from '@emotion/styled';
import { blue, grey, red } from '@mui/material/colors';

const MEMBERS = [
  {
    img: '/common/img/icons/person.png',
    cnt: 394,
    desc: '구도자',
  },
  {
    img: '/common/img/icons/dove.png',
    cnt: 404,
    desc: '수침자',
  },
  {
    img: '/common/img/icons/lamb.png',
    cnt: 110,
    desc: '잃은양',
  },
];

const StyledLargeNumber = styled(Typography)`
  font-family: '강조체';
  text-align: center;
  color: #ccc;
`;
const StyledSmallText = styled(Typography)`
  font-family: 'SCoreDream';
  font-weight: 300;
  font-size: 0.8rem;
  text-align: center;
  color: #555;
`;
const UpText = styled(Typography)`
  font-family: 'SCoreDream';
  font-size: 12px;
  color: ${red[700]};
  /* opacity: 0.7; */
`;
const DownText = styled(Typography)`
  font-family: 'SCoreDream';
  font-size: 12px;
  color: ${blue[700]};
  /* opacity: 0.7; */
`;

const GreyDivider = styled(Divider)`
  color: ${grey[700]};
`;

const ChurchStatus = () => {
  const selectedMenu = useRecoilValue(selectedMenuState);

  const temp = {
    position: '합회장',
    name: '남시창',
    phone: '010-1234-5678',
  };

  return (
    <Box
      mb={3}
      sx={{
        overflow: 'auto',
        width: '100%',
        maxHeight: 898,
      }}
    >
      <Card>
        <CardContent>
          <Stack direction="row" alignItems={'center'} spacing={1}>
            <Avatar src="/common/img/icons/leader.png" variant="circular" sx={{ width: 50, height: 50, mr: 2, backgroundColor: '#555' }} />

            <Stack direction="column">
              <Typography variant="h6">{temp.position + ' ' + temp.name}</Typography>
              <Typography variant="caption" sx={{ color: '#555' }}>
                {temp.phone}
              </Typography>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
      <Grid container mt={3}>
        <Grid item xs justifyContent={'center'}>
          <Stack>
            <Typography variant="h3" sx={{ fontFamily: '강조체', textAlign: 'center', color: '#ccc' }}>
              <CountUp end={19082} separator="," duration={2.5} />
            </Typography>
            <StyledSmallText variant="subtitle2" sx={{ textAlign: 'center', color: '#555' }}>
              교인수
            </StyledSmallText>
          </Stack>
        </Grid>
      </Grid>
      <Card style={{ marginTop: 24 }}>
        {/* <CardHeader title="교인 세대 수" /> */}
        <CardContent>
          <Grid container mt={2} mb={2}>
            <Grid item xs justifyContent={'center'}>
              <Stack>
                <StyledLargeNumber variant="h4" sx={{ fontFamily: '강조체', textAlign: 'center', color: '#ccc' }}>
                  <CountUp end={123} separator="," duration={1.5} />
                </StyledLargeNumber>
                <StyledSmallText variant="subtitle2" sx={{ textAlign: 'center', color: '#555' }}>
                  교회
                </StyledSmallText>
              </Stack>
            </Grid>
            <GreyDivider orientation="vertical" flexItem />
            <Grid item xs>
              <Stack>
                <StyledLargeNumber variant="h4" sx={{ fontFamily: '강조체', textAlign: 'center', color: '#ccc' }}>
                  <CountUp end={28} separator="," duration={1} />
                </StyledLargeNumber>
                <StyledSmallText variant="subtitle2" sx={{ textAlign: 'center', color: '#555' }}>
                  예배소
                </StyledSmallText>
              </Stack>
            </Grid>
            <GreyDivider orientation="vertical" flexItem />
            <Grid item xs>
              <Stack>
                <StyledLargeNumber variant="h4" sx={{ fontFamily: '강조체', textAlign: 'center', color: '#ccc' }}>
                  <CountUp end={2} separator="," duration={1} />
                </StyledLargeNumber>
                <StyledSmallText variant="subtitle2" sx={{ textAlign: 'center', color: '#555' }}>
                  기관
                </StyledSmallText>
              </Stack>
            </Grid>
          </Grid>
          <GreyDivider variant="middle" />

          <Grid container mt={2}>
            <Grid item xs justifyContent={'center'}>
              <Stack>
                <StyledLargeNumber variant="h4" sx={{ fontFamily: '강조체', textAlign: 'center', color: '#ccc' }}>
                  <CountUp end={pastors.length - 7} separator="," duration={1.5} />
                </StyledLargeNumber>
                <StyledSmallText variant="subtitle2" sx={{ textAlign: 'center', color: '#555' }}>
                  안수목사
                </StyledSmallText>
              </Stack>
            </Grid>
            <GreyDivider orientation="vertical" flexItem />
            <Grid item xs>
              <Stack>
                <StyledLargeNumber variant="h4" sx={{ fontFamily: '강조체', textAlign: 'center', color: '#ccc' }}>
                  <CountUp end={3} separator="," duration={1} />
                </StyledLargeNumber>
                <StyledSmallText variant="subtitle2" sx={{ textAlign: 'center', color: '#555' }}>
                  인준목사
                </StyledSmallText>
              </Stack>
            </Grid>
            <GreyDivider orientation="vertical" flexItem />
            <Grid item xs>
              <Stack>
                <StyledLargeNumber variant="h4" sx={{ fontFamily: '강조체', textAlign: 'center', color: '#ccc' }}>
                  <CountUp end={4} separator="," duration={1} />
                </StyledLargeNumber>
                <StyledSmallText variant="subtitle2" sx={{ textAlign: 'center', color: '#555' }}>
                  전도사
                </StyledSmallText>
              </Stack>
            </Grid>
            {/* <GreyDivider orientation="vertical" flexItem /> */}
            {/* <Grid item xs>
              <Stack>
                <Typography variant="h4" sx={{ fontFamily: '강조체', textAlign: 'center', color: '#ccc' }}>
                  <CountUp end={0} separator="," duration={1} />
                </Typography>
                <Typography variant="subtitle2" sx={{ textAlign: 'center', color: '#555' }}>
                  선교사
                </Typography>
              </Stack>
            </Grid> */}
          </Grid>
        </CardContent>
      </Card>

      <Stack direction="row" my={4}>
        {MEMBERS.map((member, idx) => {
          return (
            <Box key={idx} display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%">
              <Box width="100%" display="flex" justifyContent="center" alignItems="center" mb={1}>
                <Avatar src={member.img} variant="square" sx={{ width: 40, mr: 1 }} />
                <StyledLargeNumber variant="h5">
                  <CountUp end={member.cnt} separator="," />
                </StyledLargeNumber>
              </Box>
              <StyledSmallText>{member.desc}</StyledSmallText>
            </Box>
          );
        })}
      </Stack>

      <Card style={{ marginTop: 24 }}>
        <CardContent>
          <Grid container>
            <Grid item xs>
              <Stack>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'end' }}>
                  <StyledLargeNumber variant="h4" sx={{ fontFamily: '강조체', textAlign: 'center', color: '#ccc', pr: 0.5 }}>
                    <CountUp end={84} separator="," />
                  </StyledLargeNumber>
                  <StyledSmallText pb={0.5}>%</StyledSmallText>
                </Box>
                <StyledSmallText variant="subtitle2" sx={{ textAlign: 'center', color: '#555' }}>
                  건강도
                </StyledSmallText>
              </Stack>
            </Grid>
            <GreyDivider orientation="vertical" flexItem />
            <Grid item xs>
              <Stack>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'end' }}>
                  <StyledLargeNumber variant="h4" sx={{ fontFamily: '강조체', textAlign: 'center', color: '#ccc', pr: 0.5 }}>
                    <CountUp end={14} separator="," />
                  </StyledLargeNumber>
                  <StyledSmallText pb={0.5}>%</StyledSmallText>
                </Box>
                <StyledSmallText variant="subtitle2" sx={{ textAlign: 'center', color: '#555' }}>
                  성장률
                </StyledSmallText>
              </Stack>
            </Grid>
            <GreyDivider orientation="vertical" flexItem />
            <Grid item xs>
              <Stack>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'end' }}>
                  <StyledLargeNumber variant="h4" sx={{ fontFamily: '강조체', textAlign: 'center', color: '#ccc', pr: 0.5 }}>
                    <CountUp end={46} separator="," />
                  </StyledLargeNumber>
                  <StyledSmallText pb={0.5}>%</StyledSmallText>
                </Box>
                <StyledSmallText variant="subtitle2" sx={{ textAlign: 'center', color: '#555' }}>
                  정착률
                </StyledSmallText>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ChurchStatus;
