import { Box, Typography } from '@mui/material';
import { blue, grey, orange } from '@mui/material/colors';
import React from 'react';

const linearGradient = `linear-gradient(90deg, ${orange[500]} 0%, ${orange[300]} 100%)`;

type OwnProps = {};

const ChurchLegend: React.FC<OwnProps> = () => {
  return (
    <Box sx={{ position: 'absolute', zIndex: -1, top: 680, display: 'flex', justifyContent: 'end', width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              width: 30,
              height: 150,
              // backgroundColor: grey[900],
              background: `linear-gradient(0deg, #1f1f1f 0%, ${blue[400]} 100%)`,
              mr: 1,
            }}
          />
          <Box height={150} display="flex" flexDirection="column" justifyContent="space-between">
            <Typography sx={{ fontFamily: 'SCoreDream', fontWeight: 200, color: grey[600], fontSize: 12 }}>100 %</Typography>
            <Typography sx={{ fontFamily: 'SCoreDream', fontWeight: 200, color: grey[600], fontSize: 12 }}>50 %</Typography>
            <Typography sx={{ fontFamily: 'SCoreDream', fontWeight: 200, color: grey[600], fontSize: 12 }}>0 %</Typography>
          </Box>
        </Box>
        <Box width={50} />
      </Box>
    </Box>
  );
};

export default ChurchLegend;
