import { Box } from '@mui/material';
import React from 'react';

import MinistryList from './MinistryList';

interface OwnProps {}

const MinistryPage: React.FC<OwnProps> = () => {
  return (
    <Box width="100%">
      <MinistryList />
    </Box>
  );
};

export default MinistryPage;
