import { Box, Typography } from '@mui/material';
import React from 'react';
import { SetterOrUpdater, useRecoilValue } from 'recoil';

import styled from '@emotion/styled';
import { blue, grey, red } from '@mui/material/colors';
import { region } from '../../libs/data/region';
import { selectedMenuState } from '../../libs/states/common';

interface OwnProps {
  x: number;
  y: number;
  hoveringRegion: string;
  regionData: any;
  setRegionData: SetterOrUpdater<never[]>;
}
const StyledSmallNumber = styled(Typography)`
  font-family: 'SCoreDream';
  font-size: 22px;
  font-weight: 600;
  color: ${grey[100]};
  padding-right: 12px;
`;
const StyledSmallLabel = styled(Typography)`
  font-family: 'SCoreDream';
  font-size: 12px;
  font-weight: 200;
  color: ${grey[500]};
`;
const UpText = styled(Typography)`
  font-family: 'SCoreDream';
  font-size: 12px;
  color: ${red[700]};
  /* opacity: 0.7; */
`;
const DownText = styled(Typography)`
  font-family: 'SCoreDream';
  font-size: 12px;
  color: ${blue[700]};
  /* opacity: 0.7; */
`;

const RegionCard: React.FC<OwnProps> = ({ x, y, hoveringRegion, regionData, setRegionData }) => {
  const selectedMenu = useRecoilValue(selectedMenuState);

  const currentRegion = region[hoveringRegion];

  return (
    <Box style={{ transform: `translate(${x}px, ${y}px)`, position: 'fixed', top: '3px', left: '3px' }} p={3} bgcolor={grey[900]} borderTop={`3px solid ${selectedMenu.color?.primary}`}>
      <Box color="white" pb={1}>
        {currentRegion.name}
      </Box>
      {/* <Box color="white" pt={1}>
        숨 활성화 : 40%
      </Box>
      <Box color="white" pt={1}>
        숨 비활성화 : 60%
      </Box> */}
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingBottom: 1 }}>
        <StyledSmallLabel pr={2}>화요일</StyledSmallLabel>
        <StyledSmallNumber>350</StyledSmallNumber>
        <UpText>▲ 24</UpText>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingBottom: 1 }}>
        <StyledSmallLabel pr={2}>금요일</StyledSmallLabel>
        <StyledSmallNumber>247</StyledSmallNumber>
        <DownText>▼ 12</DownText>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <StyledSmallLabel pr={2}>안식일</StyledSmallLabel>
        <StyledSmallNumber>550</StyledSmallNumber>
        <UpText>▲ 45</UpText>
      </Box>
    </Box>
  );
};

export default RegionCard;
