import { Autocomplete, Avatar, AvatarGroup, Box, Card, Divider, Grid, LinearProgress, List, ListItem, Skeleton, Tab, Tabs, TextField, Typography, linearProgressClasses } from '@mui/material';
import { TabPanel } from '@mui/lab';
import { grey, yellow } from '@mui/material/colors';
import React, { useState } from 'react';
import TitleBox from '../../modules/TitleBox';

import Chart from 'react-apexcharts';
import { pastors } from '../../../libs/data/pastors';
import { events } from '../../../libs/data/events';
import styled from '@emotion/styled';

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 10,

  marginBottom: 4,
  marginTop: 4,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: grey[800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: yellow[700],
  },
}));

const AntTabs = styled(Tabs)({
  borderBottom: `1px solid ${grey[800]}`,
  fontFamily: 'SCoreDream',
  fontWeight: 300,
  fontSize: '12px',
  '& .MuiTabs-indicator': {
    backgroundColor: yellow[700],
  },
});

interface StyledTabProps {
  label: string;
}

const AntTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(({ theme }) => ({
  fontFamily: 'SCoreDream',
  fontWeight: 300,
  fontSize: '13px',
  '&:hover': {
    color: yellow[700],
    opacity: 1,
  },
  '&.Mui-selected': {
    color: yellow[700],
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff',
  },
}));

type OwnProps = {};

const MinistryList: React.FC<OwnProps> = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [value, setValue] = useState(0);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const lessTenPastors = pastors.filter((pastor) => pastor.experience === 10).sort((a, b) => a.name.localeCompare(b.name));
  const tenToThirtyPastors = pastors.filter((pastor) => pastor.experience === 20);
  const moreThirtyPastors = pastors.filter((pastor) => pastor.experience === 30);

  const filteredPastors = [lessTenPastors, tenToThirtyPastors, moreThirtyPastors];

  return (
    <Box width="100%">
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={3}>
          <TitleBox />
          <Box mt={5}></Box>
        </Grid>
        <Grid container item xs={12} md={12} lg={9}>
          <Grid item xs={12} md={12} lg={12}>
            <Card sx={{ bgcolor: grey[900], p: 2 }}>
              <Grid container pl={2}>
                <Grid item xs={12} md={12} lg={2}>
                  <Typography variant="h6" sx={{ textAlign: 'left' }}>
                    최근 행사 결과
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={2}>
                  <Typography variant="h6" sx={{ textAlign: 'left' }}>
                    다니엘 중생학교
                  </Typography>
                  <Typography variant="body2" sx={{ textAlign: 'left', fontSize: 12, color: grey[600] }}>
                    2024년 1월 15 - 17일
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={5}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Box sx={{ display: 'flex', pr: 2, alignItems: 'center' }}>
                      <Typography variant="body2" sx={{ textAlign: 'left', fontSize: 12, color: grey[600], pr: 1 }}>
                        강사
                      </Typography>
                      <Typography variant="h6" sx={{ textAlign: 'left', pr: 1 }}>
                        송바다 목사님
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', pr: 2, alignItems: 'center' }}>
                      <Typography variant="body2" sx={{ textAlign: 'left', fontSize: 12, color: grey[600], pr: 1 }}>
                        장소
                      </Typography>
                      <Typography variant="h6" sx={{ textAlign: 'left', pr: 1 }}>
                        삼육청도연수원
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={12} lg={3}>
                  <Box sx={{ display: 'flex', px: 2 }}>
                    <Typography variant="subtitle2">참여 목사</Typography>
                    <AvatarGroup total={7} sx={{ ml: 'auto' }}>
                      <Avatar src="/common/img/icons/person.png" sx={{ backgroundColor: '#ccc' }} />
                      <Avatar src="/common/img/icons/person.png" sx={{ backgroundColor: '#ccc' }} />
                      <Avatar src="/common/img/icons/person.png" sx={{ backgroundColor: '#ccc' }} />
                    </AvatarGroup>
                  </Box>
                </Grid>
                <Grid item xs={12} md={12} lg={2} />

                <Grid container item xs={12} md={12} lg={7} p={1}>
                  <Grid item xs={12} md={12} lg={2} pt={1}>
                    <Typography variant="body2" sx={{ textAlign: 'left', fontSize: 12, color: grey[600], pr: 1 }}>
                      참여자 수
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12} lg={10} pt={1}>
                    <BorderLinearProgress value={40} variant="determinate" sx={{ width: 450 }} />
                  </Grid>
                  <Grid item xs={12} md={12} lg={2} pt={1}>
                    <Typography variant="body2" sx={{ textAlign: 'left', fontSize: 12, color: grey[600], pr: 1 }}>
                      비용
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12} lg={10} pt={1}>
                    <BorderLinearProgress value={40} variant="determinate" sx={{ width: 450 }} />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12} lg={3}>
                  <Box sx={{ display: 'flex', mt: 2, px: 2 }}>
                    <Typography variant="subtitle2">참여 교회</Typography>
                    <AvatarGroup total={7} sx={{ ml: 'auto' }}>
                      <Avatar src="/common/img/icons/church.png" sx={{ backgroundColor: '#ccc' }} />
                      <Avatar src="/common/img/icons/church.png" sx={{ backgroundColor: '#ccc' }} />
                      <Avatar src="/common/img/icons/church.png" sx={{ backgroundColor: '#ccc' }} />
                    </AvatarGroup>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        <Grid container item xs={12} md={12} lg={9} spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <Card sx={{ bgcolor: grey[900], p: 2 }}>
              <AntTabs textColor="secondary" indicatorColor="secondary" value={selectedTab} onChange={handleChange} variant="fullWidth" scrollButtons="auto">
                <AntTab label="영남합회" />
                <AntTab label="대구경산" />
                <AntTab label="경북북부" />
                <AntTab label="경북중부" />
                <AntTab label="경북서부" />
                <AntTab label="동해안" />
                <AntTab label="부산남부" />
                <AntTab label="부산북부" />
                <AntTab label="경남동부" />
                <AntTab label="경남남부" />
                <AntTab label="경남서부" />
              </AntTabs>
              <List sx={{ maxHeight: 400, overflow: 'auto' }}>
                {events.map((event, idx) => (
                  <Box key={idx}>
                    <ListItem sx={{ py: 2 }}>
                      <Grid container pl={2}>
                        <Grid item xs={12} md={12} lg={4}>
                          <Typography variant="h6" sx={{ textAlign: 'left' }}>
                            {event.title}
                          </Typography>
                          <Typography variant="body2" sx={{ textAlign: 'left', fontSize: 12, color: grey[600] }}>
                            {event.date}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} lg={4}>
                          <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                            <Box sx={{ display: 'flex', pr: 2, alignItems: 'center' }}>
                              <Typography variant="body2" sx={{ textAlign: 'left', fontSize: 10, color: grey[600], pr: 1 }}>
                                강사
                              </Typography>
                              <Typography sx={{ textAlign: 'left', pr: 1, fontSize: 16 }}>{event.lecturer}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', pr: 2, alignItems: 'center' }}>
                              <Typography variant="body2" sx={{ textAlign: 'left', fontSize: 10, color: grey[600], pr: 1 }}>
                                장소
                              </Typography>
                              <Typography sx={{ textAlign: 'left', pr: 1, fontSize: 16 }}>{event.location}</Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={12} lg={4}>
                          <Box sx={{ display: 'flex', px: 2 }}>
                            <Typography variant="subtitle2">참여 목사</Typography>
                            <AvatarGroup total={7} sx={{ ml: 'auto' }}>
                              <Avatar src="/common/img/icons/person.png" sx={{ backgroundColor: '#ccc' }} />
                              <Avatar src="/common/img/icons/person.png" sx={{ backgroundColor: '#ccc' }} />
                              <Avatar src="/common/img/icons/person.png" sx={{ backgroundColor: '#ccc' }} />
                              <Avatar src="/common/img/icons/person.png" sx={{ backgroundColor: '#ccc' }} />
                            </AvatarGroup>
                          </Box>
                        </Grid>

                        <Grid container item xs={12} md={12} lg={8} p={1}>
                          <Grid item xs={12} md={12} lg={2} pt={1}>
                            <Typography variant="body2" sx={{ textAlign: 'left', fontSize: 12, color: grey[600], pr: 1 }}>
                              참여자 수
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={12} lg={10} pt={1}>
                            <BorderLinearProgress value={event.participate} variant="determinate" sx={{ width: 500 }} />
                          </Grid>
                          <Grid item xs={12} md={12} lg={2} pt={1}>
                            <Typography variant="body2" sx={{ textAlign: 'left', fontSize: 12, color: grey[600], pr: 1 }}>
                              비용
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={12} lg={10} pt={1}>
                            <BorderLinearProgress value={event.cost} variant="determinate" sx={{ width: 500 }} />
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={12} lg={4}>
                          <Box sx={{ display: 'flex', mt: 2, px: 2 }}>
                            <Typography variant="subtitle2">참여 교회</Typography>
                            <AvatarGroup total={7} sx={{ ml: 'auto' }}>
                              <Avatar src="/common/img/icons/church.png" sx={{ backgroundColor: '#ccc' }} />
                              <Avatar src="/common/img/icons/church.png" sx={{ backgroundColor: '#ccc' }} />
                              <Avatar src="/common/img/icons/church.png" sx={{ backgroundColor: '#ccc' }} />
                              <Avatar src="/common/img/icons/church.png" sx={{ backgroundColor: '#ccc' }} />
                            </AvatarGroup>
                          </Box>
                        </Grid>
                      </Grid>
                    </ListItem>
                    {events.length - 1 !== idx && <Divider />}
                  </Box>
                ))}
              </List>
            </Card>
          </Grid>

          <Grid item xs={12} md={12} lg={4}>
            <Card sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', bgcolor: grey[900], p: 2 }}>
              <Chart
                options={{
                  chart: {
                    height: 200,
                    type: 'radialBar',
                  },
                  plotOptions: {
                    radialBar: {
                      hollow: {
                        size: '70%',
                      },
                    },
                  },
                  colors: [yellow[700]],
                  labels: ['목표 달성률'],
                  legend: {
                    show: false,
                  },
                }}
                series={[70]}
                type="radialBar"
                height={200}
              />
            </Card>
          </Grid>
          <Grid item xs={12} md={12} lg={4}>
            <Card sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', bgcolor: grey[900], p: 2 }}>
              <Chart
                options={{
                  chart: {
                    height: 200,
                    type: 'radialBar',
                  },
                  plotOptions: {
                    radialBar: {
                      hollow: {
                        size: '70%',
                      },
                    },
                  },
                  colors: [yellow[700]],
                  labels: ['비용'],
                  legend: {
                    show: false,
                  },
                }}
                series={[70]}
                type="radialBar"
                height={200}
              />
            </Card>
          </Grid>
          <Grid item xs={12} md={12} lg={4}>
            <Card sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', bgcolor: grey[900], p: 2 }}>
              <Chart
                options={{
                  chart: {
                    height: 200,
                    type: 'radialBar',
                  },
                  plotOptions: {
                    radialBar: {
                      hollow: {
                        size: '70%',
                      },
                    },
                  },
                  colors: [yellow[700]],
                  labels: ['총 사업 수'],
                  legend: {
                    show: false,
                  },
                }}
                series={[70]}
                type="radialBar"
                height={200}
              />
            </Card>
          </Grid>
        </Grid>
        <Grid container item xs={12} md={12} lg={3}>
          <Card sx={{ bgcolor: grey[900], p: 2, width: '100%' }}>
            <Autocomplete //
              options={pastors}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="목사 검색" />}
            />
            <AntTabs textColor="secondary" indicatorColor="secondary" value={value} onChange={handleChangeTab} variant="fullWidth">
              <AntTab label="10년차 이하" />
              <AntTab label="10 ~ 30년차" />
              <AntTab label="30년차 이상" />
            </AntTabs>

            <List sx={{ maxHeight: 570, overflow: 'auto' }}>
              {filteredPastors[value].map((pastor, idx) => (
                <Box key={idx}>
                  <ListItem>
                    <Box>
                      <Box sx={{ display: 'flex', mb: 1 }}>
                        <Avatar src="/common/img/icons/person.png" sx={{ width: 50, height: 50, backgroundColor: '#ccc', mr: 1 }} />

                        <Box>
                          <Typography variant="h6">{pastor.name}</Typography>
                          <Typography variant="subtitle2" sx={{ color: grey[700] }}>
                            {pastor.church}
                          </Typography>
                        </Box>
                      </Box>
                      <Typography sx={{ color: grey[700], fontSize: 12 }}>현재 참여 사업</Typography>
                      <Typography variant="body2">다니엘 중생학교, 디지털 미션위크, 지역별 장막부흥회</Typography>
                    </Box>
                  </ListItem>
                  {filteredPastors[value].length - 1 !== idx && <Divider />}
                </Box>
              ))}
            </List>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MinistryList;
