import React, { useEffect, useState } from 'react';
import { orange } from '@mui/material/colors';

import { Box, Button } from '@mui/material';

import { Region } from '../Map';
import RegionCard from '../RegionCard';

import SekcMap from '../SekcMap';
import { useRecoilState } from 'recoil';
import { isOpenRegionAtom } from '../../../libs/states/map';

const useMousePosition = () => {
  const [mousePosition, setMousePosition] = useState({ x: null, y: null });

  const updateMousePosition = (ev) => {
    setMousePosition({ x: ev.clientX, y: ev.clientY });
  };

  useEffect(() => {
    window.addEventListener('mousemove', updateMousePosition);

    return () => window.removeEventListener('mousemove', updateMousePosition);
  }, []);

  return mousePosition;
};

const WorshipMap = () => {
  const [hoveringRegion, setHoveringRegion] = useState(null);
  const [isHovering, setIsHovering] = useState(false);
  const { x, y } = useMousePosition();

  const [openRegion, setOpenRegion] = useState(null);
  const [isOpenRegion, setIsOpenRegion] = useRecoilState(isOpenRegionAtom);

  const handleMouseEnter = (region) => {
    setIsHovering(true);
    setHoveringRegion(region);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
    setHoveringRegion(null);
  };

  const handleClick = (region) => {
    if (isOpenRegion) return setIsOpenRegion(false);
    setOpenRegion(region);
    setIsOpenRegion(true);
  };

  const regionStyle = (region, transformOrigin) => {
    return {
      filter: hoveringRegion && hoveringRegion !== region ? 'blur(3px)' : '', //
      transition: 'filter 0.5s, fill 0.5s, opacity 0.5s, transform 0.5s',
      stroke: hoveringRegion === region ? orange[500] : hoveringRegion && hoveringRegion !== region ? '' : '',
      fill: hoveringRegion === region ? orange[700] : hoveringRegion && hoveringRegion !== region ? '' : '#1f1f1f',
      opacity: hoveringRegion && hoveringRegion !== region ? '0.5' : '1',
      transform: hoveringRegion === region ? 'scale(1.1)' : '',
      transformOrigin: transformOrigin,
      curser: 'pointer',
      zIndex: hoveringRegion === region ? 1 : 0,
    };
  };

  return (
    <Box>
      <Box top={5} px={2} sx={{ position: 'reletive', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {/* <Location mainService={mainService} /> */}
        {isOpenRegion && (
          <Box display="flex" flexDirection="column">
            <Region openRegion={openRegion} handleMouseEnter={handleMouseEnter} handleMouseLeave={handleMouseLeave} regionStyle={regionStyle} handleClick={handleClick} />
            <Box display="flex" justifyContent="end" px={3} pb={3}>
              <Button variant="outlined" onClick={() => setIsOpenRegion(false)}>
                뒤로가기
              </Button>
            </Box>
          </Box>
        )}
        {!isOpenRegion && (
          <>
            {isHovering && <RegionCard x={x} y={y} hoveringRegion={hoveringRegion} />}
            <SekcMap handleMouseEnter={handleMouseEnter} handleMouseLeave={handleMouseLeave} regionStyle={regionStyle} handleClick={handleClick} />
          </>
        )}
      </Box>
    </Box>
  );
};

export default WorshipMap;
