import { blue, cyan, lime, orange, purple, yellow } from '@mui/material/colors';
import { subQuarters } from 'date-fns';
import { atom, selector } from 'recoil';
import { Menu } from '../types/common';


export const stageAtom = atom({
    key: 'stage',
    default: 0,
});

export const sydaBoxAtom = atom({
    key: 'isSydaBoxOpen',
    default: true,
});

export const postOpenAtom = atom({
    key: 'isPostOpen',
    default: false,
});

export const currentTermAtom = atom({
    key: 'currentTerm',
    default: subQuarters(new Date(), 1),
});

export const currentDtAtom = atom({
    key: 'currentDt',
    default: 0,
});
export const previousTermAtom = atom({
    key: 'previousTerm',
    default: subQuarters(new Date(), 2),
});

export const previousDtAtom = atom({
    key: 'previousDt',
    default: 0,
});

export const menuListAtom = atom<Menu[]>({
    key: 'menuList',
    default: [
        {
            index: 0,
            title: '예배현황',
            icon: 'assessment',
            color: {
                primary: orange[500],
                secondary: cyan[500],
                tertiary: orange[800],
            },
        },
        {
            index: 1,
            title: '교회현황',
            icon: 'church',
            color: {
                primary: blue[400],
                secondary: blue[600],
                tertiary: blue[800],
            },
        },
        {
            index: 2,
            title: '디지털선교현황',
            icon: 'import_contacts',
            color: {
                primary: '#78b8cc',
                secondary: '#4B6269',
                tertiary: '#575c7e',
            },
        },
        {
            index: 3,
            title: '사업현황',
            icon: 'terrain',
            color: {
                primary: yellow[700],
                secondary: blue[700],
                tertiary: yellow[900],
            },
        },
        // {
        //     title: '지역사회현황',
        //     icon: 'groups'
        // },
        // {
        //     title: '구호현황',
        //     icon: 'volunteer_activism'
        // },
    ],
});

export const selectedMenuState = atom({
    key: 'selectedMenuState',
    default: selector({
      key: 'defaultSelectedMenu',
      get: ({get}) => {
        const menu = get(menuListAtom);
        return menu[0];  // Set the default selected menu to the first title
      },
    }),
  });

export const colorsAtom = atom({
    key: 'colors',
    default:[
        {
            primary: 'bg-orange-500',
            secondary: 'bg-cyan-500',
            tertiary: 'bg-orange-800',
        },
        {
            primary: 'bg-green-300',
            secondary: 'bg-red-300',
            tertiary: 'bg-red-700',
        },
        {
            primary: 'bg-purple-300',
            secondary: 'bg-lime-400',
            tertiary: 'bg-purple-700',
        },
        {
            primary: 'bg-teal-300',
            secondary: 'bg-amber-900',
            tertiary: 'bg-teal-700',
        },
        {
            primary: 'bg-yellow-700',
            secondary: 'bg-blue-700',
            tertiary: 'bg-yellow-900',
        },
    ]
});