export const events = [
  {
    title: '다니엘 비전학교',
    date: '2024년 1월 8 - 11일',
    department: '청소년부',
    location: '삼육청도연수원',
    lecturer: '엄인준 목사님',
    cost: 84,
    participate: 69,
  },
  {
    title: '다니엘 중생학교',
    date: '2024년 1월 15 - 17일',
    department: '어린이부',
    location: '삼육청도연수원',
    lecturer: '송바다 목사님',
    cost: 76,
    participate: 61,
  },
  {
    title: '영남합회 패스파인더 연합캠포리',
    date: '2024년 5월 3 - 6일',
    department: '청소년부',
    location: '삼육청도연수원',
    lecturer: '미정',
    cost: 79,
    participate:  64,
  },
  {
    title: '디지털 미션위크',
    date: '2024년 6월 1 - 8일',
    department: '디지털선교부',
    location: '삼육청도연수원',
    lecturer: '온라인',
    cost: 73,
    participate:80,
  },
  {
    title: '전국 대학생 여름수련회',
    date: '2024년 6월 28일 - 7월 1일',
    department: '청소년부',
    location: '안면도연수원',
    lecturer: '미정',
    cost: 78,
    participate:90,
  },
  {
    title: 'GAIN GLOBAL 컨퍼런스',
    date: '2024년 7월 7일 - 14일',
    department: '디지털선교부',
    location: '태국',
    lecturer: '미정',
    cost: 68,
    participate:70,
  },
  {
    title: 'NSD-SSD 여성리더 정상회',
    date: '2024년 7월 18일 - 21일',
    department: '여성전도부',
    location: '태국',
    lecturer: '미정',
    cost: 92,
    participate:70,
  },
  {
    title: '지역별 장막부흥회',
    date: '2024년 7 - 8월 중',
    department: '선교부',
    location: '각 지역',
    lecturer: '미정',
    cost: 80,
    participate:60,
  },
  {
    title: '제2 회 3050 패밀리캠프',
    date: '2024년 8월 2 - 4일',
    department: '선교부',
    location: '미정',
    lecturer: '미정',
    cost: 70,
    participate:80,
  },
  {
    title: '패스파인더 국제캠포리',
    date: '2024년 8월 5 - 11일',
    department: '청소년부',
    location: '질레트 와이오밍',
    lecturer: '미정',
    cost: 96,
    participate:78
  },
  {
    title: '선교 120주년기념 연합 장애인 장막회',
    date: '2024년 8월 15 - 18일',
    department: '보건복지부',
    location: '장수농업연수원',
    lecturer: '미정',
    cost: 76,
    participate:86
  },
  {
    title: '청년 여름야영회',
    date: '2024년 8월 23 - 25일',
    department: '청소년부',
    location: '삼육청도연수원',
    lecturer: '미정',
    cost: 73,
    participate:81
  },
  {
    title: '성경탐험대회 합회 대회',
    date: '2024년 9월 7일',
    department: '청소년부',
    location: '영남삼육',
    lecturer: '미정',
    cost: 69,
    participate:77
  },
  {
    title: '영남 장애인 장막회',
    date: '2024년 9월 24 - 26일',
    department: '보건복지부',
    location: '성부/안식원',
    lecturer: '미정',
    cost: 82,
    participate:89
  },
  {
    title: '성경탐험대회 연합회 대회',
    date: '2024년 9월 29일',
    department: '청소년부',
    location: '충청지역',
    lecturer: '미정',
    cost: 89,
    participate:67
  },
  {
    title: 'Global Family Camp',
    date: '2024년 10월 5 - 6일',
    department: '가정봉사부',
    location: '삼육청도연수원',
    lecturer: '미정',
    cost: 88,
    participate:83
  },
  {
    title: '3040가족, 어린이캠프',
    date: '2024년 10월 7 - 9일',
    department: '어린이부',
    location: '미정',
    lecturer: '미정',
    cost:71 ,
    participate:78
  },
  {
    title: '디지털 미션위크',
    date: '2024년 10월 19 - 26일',
    department: '디지털선교부',
    location: '온라인',
    lecturer: '미정',
    cost:80 ,
    participate:67
  },
  {
    title: '선교 120주년 기념예배',
    date: '2024년 11월 8 - 10일',
    department: '총무부',
    location: '삼육대학교',
    lecturer: '미정',
    cost: 75,
    participate:82
  },
  {
    title: '영남합회 청년동기신학',
    date: '2024년 12월 27 - 29일',
    department: '청소년부',
    location: '삼육청도연수원',
    lecturer: '미정',
    cost:90 ,
    participate:78
  },

]