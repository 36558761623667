import styled from '@emotion/styled';
import { Box, Icon, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

import { useRecoilState, useRecoilValue } from 'recoil';
import { menuListAtom, selectedMenuState } from '../../../libs/states/common';
import { Menu } from '../../../libs/types/common';

const StyledIcon = styled(Icon)`
  margin-right: 8px;
  font-size: 20px;
`;
const StyledTitle = styled(Typography)`
  margin-right: 12px;
  font-size: 11px;
  font-family: 'SCoreDream';
  font-weight: 300;
`;

const SideMenu = () => {
  const [selectedMenu, setSelectedMenu] = useRecoilState(selectedMenuState);

  const MenuList = useRecoilValue(menuListAtom);

  const handleClick = (menu: Menu) => {
    setSelectedMenu(menu);
  };

  return (
    <Box mb={11} sx={{}}>
      <Box sx={{ border: `1px solid ${grey[800]}`, borderTop: `2px solid ${selectedMenu.color?.primary}`, backgroundColor: grey[900] }}>
        <List disablePadding sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
          {MenuList.map((menu, index) => {
            return (
              <ListItem key={index} sx={{ borderTop: index === 0 ? '' : `1px solid ${grey[800]}`, p: 0 }}>
                <ListItemButton
                  onClick={() => handleClick(menu)}
                  sx={{
                    backgroundColor: menu.title === selectedMenu.title ? menu.color?.primary : grey[900],
                    '&:hover': {
                      backgroundColor: menu.title === selectedMenu.title ? menu.color?.primary : grey[900],
                    },
                    p: 1,
                    pt: 0.5,
                    pb: 0.5,
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 20 }}>
                    <StyledIcon className="material-icons" sx={{ color: menu.title === selectedMenu.title ? grey[800] : grey[300] }}>
                      {menu.icon}
                    </StyledIcon>
                  </ListItemIcon>
                  <ListItemText>
                    <StyledTitle sx={{ color: menu.title === selectedMenu.title ? grey[800] : grey[300] }}>{menu.title}</StyledTitle>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Box>
  );
};

export default SideMenu;
