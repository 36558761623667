import React from 'react';
import { DK, EKN, ES, MKB, NBS, NKB, SBS, SKN, WKB, WKN } from './Map';

type OwnProps = {
  handleMouseEnter: (region: string) => void;
  handleMouseLeave: () => void;
  regionStyle: (region: string, transformOrigin: string, randomNumber: number) => {};
  handleClick: (region: string) => void;
};

const SekcMap: React.FC<OwnProps> = ({ handleClick, handleMouseEnter, handleMouseLeave, regionStyle }) => {
  return (
    <svg
      height={800}
      width="100%"
      stroke-miterlimit="10"
      style={{ fillRule: 'nonzero', clipRule: 'evenodd', strokeLinecap: 'round', strokeLinejoin: 'round' }}
      version="1.1"
      viewBox="91.8524 73.4119 593.469 803"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs />
      <g id="2024_영남_지역_지도">
        <DK handleMouseEnter={handleMouseEnter} handleMouseLeave={handleMouseLeave} regionStyle={regionStyle} handleClick={handleClick} />
        <NBS handleMouseEnter={handleMouseEnter} handleMouseLeave={handleMouseLeave} regionStyle={regionStyle} handleClick={handleClick} />
        <SBS handleMouseEnter={handleMouseEnter} handleMouseLeave={handleMouseLeave} regionStyle={regionStyle} handleClick={handleClick} />
        <NKB handleMouseEnter={handleMouseEnter} handleMouseLeave={handleMouseLeave} regionStyle={regionStyle} handleClick={handleClick} />
        <MKB handleMouseEnter={handleMouseEnter} handleMouseLeave={handleMouseLeave} regionStyle={regionStyle} handleClick={handleClick} />
        <WKB handleMouseEnter={handleMouseEnter} handleMouseLeave={handleMouseLeave} regionStyle={regionStyle} handleClick={handleClick} />
        <ES handleMouseEnter={handleMouseEnter} handleMouseLeave={handleMouseLeave} regionStyle={regionStyle} handleClick={handleClick} />
        <EKN handleMouseEnter={handleMouseEnter} handleMouseLeave={handleMouseLeave} regionStyle={regionStyle} handleClick={handleClick} />
        <SKN handleMouseEnter={handleMouseEnter} handleMouseLeave={handleMouseLeave} regionStyle={regionStyle} handleClick={handleClick} />
        <WKN handleMouseEnter={handleMouseEnter} handleMouseLeave={handleMouseLeave} regionStyle={regionStyle} handleClick={handleClick} />
      </g>
    </svg>
  );
};

export default SekcMap;
