import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

import { useRecoilValue } from 'recoil';
import { selectedMenuState } from '../../libs/states/common';

const StyledTitle1 = styled(Typography)`
  font-family: 'SCoreDream';
  font-size: 32px;
  font-weight: 600;
  color: ${grey[100]};
  text-align: 'center';
`;
const StyledTitle2 = styled(Typography)`
  font-family: 'SCoreDream';
  font-size: 45px;
  font-weight: 600;
  text-align: 'center';
`;

type OwnProps = {};

const TitleBox: React.FC<OwnProps> = () => {
  const selectedMenu = useRecoilValue(selectedMenuState);

  return (
    <Box position="absolute">
      <StyledTitle1>한눈에 보는 영남</StyledTitle1>
      <StyledTitle2 sx={{ color: selectedMenu.color?.primary }}>{selectedMenu.title}</StyledTitle2>
    </Box>
  );
};

export default TitleBox;
