import { Box, Card, Tooltip } from '@mui/material';
import { blue, grey, purple } from '@mui/material/colors';

import { IconHelpCircle } from '@tabler/icons-react';
import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { chartStyle } from '../../../../libs/theme/chart';

const Growth = () => {
  const chartSeries: ApexOptions['series'] = [
    {
      name: '성장률',
      data: [12, 15, 16, 14, 21],
    },
  ];

  const chartOptions: ApexOptions = {
    chart: {
      type: 'line',
      toolbar: { show: false },
      zoom: { enabled: false },
    },
    stroke: {
      curve: 'smooth',
      width: [2],
    },
    markers: {
      size: [4],
    },
    xaxis: {
      categories: ['4월', '5월', '6월', '7월', '8월'],

      labels: { ...chartStyle.labels },
    },

    yaxis: [
      {
        labels: { ...chartStyle.labels },
      },
    ],

    legend: {
      position: 'top',
      offsetY: 0,
      ...chartStyle.legend,
    },
    title: {
      text: '성장률',
      style: {
        ...chartStyle.title,
      },
    },
    plotOptions: {
      // bar: {
      //   horizontal: false,
      //   borderRadius: 3,
      //   dataLabels: {
      //     total: {
      //       enabled: true,
      //       style: {
      //         fontSize: '10',
      //         fontFamily: 'SCoreDream',
      //         color: grey[300],
      //       },
      //     },
      //   },
      // },
    },
    // dataLabels: {
    //   style: {
    //     fontSize: '10',
    //     fontFamily: 'SCoreDream',
    //     colors: [grey[300], grey[800], grey[300], grey[400]],
    //   },
    // },
    tooltip: {
      theme: 'dark',
    },
    colors: [blue[200]],
    grid: { ...chartStyle.grid },
  };

  return (
    <Card sx={{ backgroundColor: grey[900], p: 2, mb: 2 }}>
      <Box position="absolute" zIndex={10} right={60}>
        <Tooltip title="3대 성장 지표와 기말보고 데이터를 통해 산출" placement="bottom">
          <IconHelpCircle size={20} color={grey[600]} />
        </Tooltip>
      </Box>
      <ReactApexChart options={chartOptions} series={chartSeries} type="line" width="100%" height={241} />
    </Card>
  );
};

export default Growth;
