import { Box, Button } from '@mui/material';
import { useState } from 'react';

import { Region } from '../Map';
import RegionCard from '../RegionCard';
import SekcMap from '../SekcMap';

import { useRecoilState } from 'recoil';
import { isOpenRegionAtom } from '../../../libs/states/map';

import { useMousePosition } from '../../../libs/hooks/map';
import { prayerDataAtom } from '../../../libs/states/prayer';

const colors = ['#1f1f1f', '#3F4E54', '#546C76', '#6D909F', '#78b8cc'];

const PrayerMap = () => {
  const [hoveringRegion, setHoveringRegion] = useState('');
  const [isHovering, setIsHovering] = useState(false);
  const [openRegion, setOpenRegion] = useState('');
  const { x, y } = useMousePosition();

  const [isOpenRegion, setIsOpenRegion] = useRecoilState(isOpenRegionAtom);
  const [prayerData, setPrayerData] = useRecoilState(prayerDataAtom);

  const handleMouseEnter = (region: string) => {
    setIsHovering(true);
    setHoveringRegion(region);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
    setHoveringRegion('');
  };

  const handleClick = (region: string) => {
    // if (isOpenRegion) return setIsOpenRegion(false);
    // setOpenRegion(region);
    // setIsOpenRegion(true);
  };

  const regionStyle = (region: string, transformOrigin: string, randomNumber: number) => {
    return {
      // filter: hoveringRegion && hoveringRegion !== region ? 'blur(3px)' : '', //
      // transition: 'filter 0.5s, fill 0.5s, opacity 0.5s, transform 0.5s',
      stroke: '',
      fill: colors[randomNumber],
      // fill: '#1f1f1f',
      // opacity: hoveringRegion && hoveringRegion !== region ? '0.5' : '1',
      // transform: hoveringRegion === region ? 'scale(1.1)' : '',
      // transformOrigin: transformOrigin,
      curser: 'none',
      // zIndex: hoveringRegion === region ? 1 : 0,
    };
  };

  return (
    <Box width="100%">
      <Box top={5} px={2} sx={{ position: 'reletive', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {/* <Location mainService={mainService} /> */}
        {isOpenRegion && (
          <Box display="flex" flexDirection="column">
            <Region openRegion={openRegion} handleMouseEnter={handleMouseEnter} handleMouseLeave={handleMouseLeave} regionStyle={regionStyle} handleClick={handleClick} />
            <Box display="flex" justifyContent="end" px={3} pb={3}>
              <Button variant="outlined" onClick={() => setIsOpenRegion(false)}>
                뒤로가기
              </Button>
            </Box>
          </Box>
        )}
        {!isOpenRegion && (
          <>
            {/* {isHovering && <RegionCard x={x} y={y} hoveringRegion={hoveringRegion} regionData={prayerData} setRegionData={setPrayerData} />} */}
            <SekcMap handleMouseEnter={handleMouseEnter} handleMouseLeave={handleMouseLeave} regionStyle={regionStyle} handleClick={handleClick} />
          </>
        )}
      </Box>
    </Box>
  );
};

export default PrayerMap;
