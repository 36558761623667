
function required(key, defaultValue = undefined) {
    const value = process.env[key] || defaultValue;

    return value;
}

export const config = {
    server: {
        cornerstone: required('REACT_APP_BACKEND_SERVER_URL'),
    },
};