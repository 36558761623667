import { Box, Grid } from '@mui/material';
import React from 'react';
import TitleBox from '../../modules/TitleBox';
import ChurchMap from './ChurchMap';
import { TITLE_BOX_HEIGHT } from '../../../libs/config/constants';
import ChurchSidePage from './ChurchSidePage';

import ChurchStatus from './ChurchStatus';
import ChurchTab from './ChurchTab';

import ChurchLegend from './ChurchLegend';

interface OwnProps {}

const ChurchPage: React.FC<OwnProps> = () => {
  return (
    <Box>
      <Grid container spacing={3}>
        {/* <Grid container item xs={12} md={12} lg={8}> */}
        <Grid item xs={12} md={12} lg={3}>
          <TitleBox />
          <Box mt={TITLE_BOX_HEIGHT + 8}>
            <ChurchStatus />
          </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Box sx={{ position: 'relative' }}>
            <ChurchTab />
            <Box sx={{ display: 'flex', justifyContent: 'space-around', mb: 2 }}>
              <ChurchMap />
            </Box>
            <ChurchLegend />
          </Box>
        </Grid>
        {/* </Grid> */}
        <Grid item xs={12} md={12} lg={3}>
          <ChurchSidePage />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ChurchPage;
