import React, { useEffect, useMemo } from 'react';

import palette from './libs/theme/palette';
import typography from './libs/theme/typography';

// import { CommonProvider } from './contexts/Common';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Main from './components/pages/Main';
import Header from './components/modules/Header';
import Footer from './components/modules/Footer';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { allReportDataAtom, baptismAtom, sumReportDataAtom } from './libs/states/church/church';
import { currentDtAtom, currentTermAtom, previousDtAtom, previousTermAtom } from './libs/states/common';
import { startOfQuarter } from 'date-fns';

const App = ({ mainService, additionService }) => {
  const themeOptions = useMemo(
    () => ({
      palette,
      shape: { borderRadius: 8 },
      typography,
    }),
    [],
  );
  const [allReportData, setAllReportData] = useRecoilState(allReportDataAtom);
  const [sumReportData, setSumReportData] = useRecoilState(sumReportDataAtom);
  const [baptism, setBaptism] = useRecoilState(baptismAtom);

  const currentTerm = useRecoilValue(currentTermAtom);
  const previousTerm = useRecoilValue(previousTermAtom);

  const setCurrentDt = useSetRecoilState(currentDtAtom);
  const setPreviousDt = useSetRecoilState(previousDtAtom);

  useEffect(() => {
    const init = async () => {
      const reportData = await mainService.getAllQuarterReportData();
      console.log(reportData);

      const filteredReportData = reportData.filter((r) => r.area_id < 100);

      const keysToExclude = new Set(['dt', 'name', 'area_id']);

      const sumAllResult = filteredReportData.reduce((acc, obj) => {
        if (!acc[obj.dt]) {
          // If the date does not exist in the accumulator, clone the object without the keys to exclude,
          // and convert the values to numbers
          const { dt, ...rest } = obj;
          acc[obj.dt] = {};
          Object.entries(rest).forEach(([key, value]) => {
            acc[obj.dt][key] = value;
          });
        } else {
          // If the date already exists in the accumulator, add the values (converted to numbers) to the existing ones
          Object.entries(obj).forEach(([key, value]) => {
            if (!keysToExclude.has(key)) {
              acc[obj.dt][key] += value;
            }
          });
        }
        return acc;
      }, {});

      const summedReportData = Object.entries(sumAllResult).map(([dt, values]) => {
        const { name, area_id, ...rest } = values;
        return { dt, ...rest };
      });

      summedReportData.sort((a, b) => a.dt - b.dt);

      setAllReportData(filteredReportData);
      setSumReportData(summedReportData);
    };

    init();
  }, []);

  useEffect(() => {
    const init = async () => {
      let result = await mainService.getYearBaptismData();
      console.log(result);
      setBaptism(result);
    };
    init();
  }, []);

  useEffect(() => {
    setCurrentDt(Math.floor(startOfQuarter(currentTerm).getTime() / 1000));
    setPreviousDt(Math.floor(startOfQuarter(previousTerm).getTime() / 1000));
  }, []);

  useEffect(() => {
    console.log('allReportData: ', allReportData);
  }, [allReportData]);
  useEffect(() => {
    console.log('sumReportData: ', sumReportData);
  }, [sumReportData]);

  const theme = createTheme(themeOptions);

  return (
    <ThemeProvider theme={theme}>
      {/* <CommonProvider> */}
      <Header />
      <Main mainService={mainService} additionService={additionService} />
      <Footer />
      {/* </CommonProvider> */}
    </ThemeProvider>
  );
};

export default App;
