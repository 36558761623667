import { Card } from '@mui/material';
import { grey, orange } from '@mui/material/colors';

import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { chartStyle } from '../../../../libs/theme/chart';

const SabbathSchool = () => {
  const chartSeries: ApexOptions['series'] = [
    {
      name: '반 수',
      type: 'line',
      data: [559, 562, 583, 612, 650],
    },
    {
      name: '교사 수',
      type: 'line',
      data: [764, 746, 783, 812, 850],
    },
    {
      name: '학생 수',
      type: 'bar',
      data: [8192, 8234, 8321, 8412, 8501],
    },
  ];

  const chartOptions: ApexOptions = {
    chart: {
      type: 'line',
      toolbar: { show: false },
      zoom: { enabled: false },
    },
    stroke: {
      curve: 'smooth',
      width: [2, 2, 0],
    },
    xaxis: {
      categories: ['4월', '5월', '6월', '7월', '8월'],

      labels: { ...chartStyle.labels },
    },
    yaxis: [
      {
        labels: { ...chartStyle.labels },
        min: 500,
        max: 900,
      },
      {
        labels: { ...chartStyle.labels },
        show: false,
        min: 500,
      },
      {
        labels: { ...chartStyle.labels },
        opposite: true,
      },
    ],

    legend: {
      position: 'top',
      offsetY: 0,
      ...chartStyle.legend,
    },
    title: {
      text: '안식일학교',
      style: {
        ...chartStyle.title,
      },
    },
    plotOptions: {
      // bar: {
      //   horizontal: false,
      //   borderRadius: 3,
      //   dataLabels: {
      //     total: {
      //       enabled: true,
      //       style: {
      //         fontSize: '10',
      //         fontFamily: 'SCoreDream',
      //         color: grey[300],
      //       },
      //     },
      //   },
      // },
    },
    // dataLabels: {
    //   style: {
    //     fontSize: '10',
    //     fontFamily: 'SCoreDream',
    //     colors: [grey[300], grey[800], grey[300], grey[400]],
    //   },
    // },
    tooltip: {
      theme: 'dark',
    },
    colors: [orange[200], orange[400], orange[700]],
    grid: { ...chartStyle.grid },
  };

  return (
    <Card sx={{ backgroundColor: grey[900], p: 2, mb: 2 }}>
      <ReactApexChart options={chartOptions} series={chartSeries} type="line" width="100%" height={241} />
    </Card>
  );
};

export default SabbathSchool;
