import { Box } from '@mui/material';

import { Attendance, AttendanceAge, SabbathSchool } from './charts';

const WorshipSidePage = () => {
  return (
    <Box>
      <SabbathSchool />
      <Attendance />
      <AttendanceAge />
    </Box>
  );
};

export default WorshipSidePage;
