import styled from '@emotion/styled';
import { Box, Tab, Tabs } from '@mui/material';
import { blue, grey } from '@mui/material/colors';
import { useState } from 'react';

const AntTabs = styled(Tabs)({
  borderBottom: `1px solid ${grey[800]}`,
  fontFamily: 'SCoreDream',
  fontWeight: 300,
  fontSize: '12px',
  '& .MuiTabs-indicator': {
    backgroundColor: blue[400],
  },
});

interface StyledTabProps {
  label: string;
}

const AntTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(({ theme }) => ({
  fontFamily: 'SCoreDream',
  fontWeight: 300,
  fontSize: '13px',
  '&:hover': {
    color: blue[400],
    opacity: 1,
  },
  '&.Mui-selected': {
    color: blue[400],
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff',
  },
}));

type OwnProps = {};

const ChurchTab: React.FC<OwnProps> = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [value, setValue] = useState(0);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };
  return (
    <Box mb={2}>
      <AntTabs textColor="primary" indicatorColor="primary" value={selectedTab} onChange={handleChange} variant="fullWidth">
        <AntTab label="교회 건강도" />
        <AntTab label="교회 성장률" />
        <AntTab label="교인 정착률" />
      </AntTabs>
    </Box>
  );
};

export default ChurchTab;
