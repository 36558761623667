import { Card } from '@mui/material';
import { grey } from '@mui/material/colors';

import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { chartStyle } from '../../../../libs/theme/chart';

const PrayerResponse = () => {
  const chartSeries: ApexOptions['series'] = [
    {
      name: '기도 응답률',
      type: 'line',
      data: [18, 29, 32, 35, 37],
    },
    {
      name: '응답 기도',
      type: 'area',
      data: [183, 293, 320, 350, 370],
    },
    {
      name: '미응답 기도',
      type: 'area',
      data: [-39, -61, -78, -114, -120],
    },
  ];

  const chartOptions: ApexOptions = {
    chart: {
      type: 'line',

      toolbar: { show: false },
      zoom: { enabled: false },
    },
    stroke: {
      curve: 'smooth',
      width: [2, 0, 0],
    },
    xaxis: {
      categories: ['4월', '5월', '6월', '7월', '8월'],

      labels: { ...chartStyle.labels },
    },
    yaxis: [
      {
        labels: { ...chartStyle.labels },
        opposite: true,
        min: -20,
        max: 50,
      },
      {
        labels: { ...chartStyle.labels },
        min: -200,
        max: 400,
      },
      {
        labels: { ...chartStyle.labels },
        show: false,
        max: 400,
        min: -200,
      },
    ],
    legend: {
      position: 'top',
      offsetY: 0,
      ...chartStyle.legend,
    },
    title: {
      text: '기도 응답률',
      style: {
        ...chartStyle.title,
      },
    },
    plotOptions: {
      // bar: {
      //   horizontal: false,
      //   borderRadius: 3,
      //   dataLabels: {
      //     total: {
      //       enabled: true,
      //       style: {
      //         fontSize: '10',
      //         fontFamily: 'SCoreDream',
      //         color: grey[300],
      //       },
      //     },
      //   },
      // },
    },
    // dataLabels: {
    //   style: {
    //     fontSize: '10',
    //     fontFamily: 'SCoreDream',
    //     colors: [grey[300], grey[800], grey[300], grey[400]],
    //   },
    // },
    tooltip: {
      theme: 'dark',
    },
    colors: ['#78b8cc', '#4B7380', '#CC7891'],
    grid: { ...chartStyle.grid },
  };

  return (
    <Card sx={{ backgroundColor: grey[900], p: 2, mb: 2 }}>
      <ReactApexChart options={chartOptions} series={chartSeries} type="line" width="100%" height={241} />
    </Card>
  );
};

export default PrayerResponse;
