import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

import VisionCard from './VisionCard';

const visionData = [
  {
    number: '700',
    unit: '명',
    label: '수침자',
  },
  {
    number: '100억',
    unit: '원',
    label: '교회십일금',
  },
  {
    number: '15,000',
    unit: '명',
    label: '안교생',
  },
  {
    number: '11,000',
    unit: '명',
    label: '평균출석생',
  },
];

const Vision = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography sx={{ fontSize: '24px', color: grey[100], fontFamily: 'SCoreDream', fontWeight: 500 }} pb={1}>
          {currentYear} 영남 비전
        </Typography>
        <Button sx={{ fontSize: '10px', color: grey[500], fontFamily: 'SCoreDream', fontWeight: 200, paddingBottom: 1 }}>더보기</Button>
      </Box>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
        {visionData.map((vision) => {
          return <VisionCard number={vision.number} unit={vision.unit} label={vision.label} />;
        })}
      </Box>
    </Box>
  );
};

export default Vision;
