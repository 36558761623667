import { amber, blue, cyan, green, grey, lime, orange, purple, red, teal, yellow } from '@mui/material/colors';

const createGradient = (color1, color2) => {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
};

// SETUP COLORS
const GREY = {
  50: grey[50],
  100: grey[100],
  200: grey[200],
  300: grey[300],
  400: grey[400],
  500: grey[500],
  600: grey[600],
  700: grey[700],
  800: grey[800],
  900: grey[900],
};

const PRIMARY = {
  lighter: orange[100],
  light: orange[300],
  main: orange[500],
  dark: orange[700],
  darker: orange[900],
  contrastText: '#fff',
};
const SECONDARY = {
  lighter: teal[100],
  light: teal[300],
  main: yellow[700],
  dark: teal[700],
  darker: teal[900],
  contrastText: '#fff',
};
const INFO = {
  lighter: blue[100],
  light: blue[300],
  main: blue[500],
  dark: blue[700],
  darker: blue[900],
  contrastText: '#fff',
};
const SUCCESS = {
  lighter: green[100],
  light: green[300],
  main: green[500],
  dark: green[700],
  darker: green[900],
  contrastText: '#fff',
};
const WARNING = {
  lighter: yellow[100],
  light: yellow[300],
  main: yellow[500],
  dark: yellow[700],
  darker: yellow[900],
  contrastText: '#fff',
};
const ERROR = {
  lighter: red[100],
  light: red[300],
  main: red[500],
  dark: red[700],
  darker: red[900],
  contrastText: '#fff',
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = [
  {
    primary: orange[500],
    secondary: cyan[500],
    tertiary: orange[800],
  },
  {
    primary: green[300],
    secondary: red[300],
    tertiary: red[700],
  },
  {
    primary: teal[300],
    secondary: amber[900],
    tertiary: teal[700],
  },
  {
    primary: yellow[700],
    secondary: blue[700],
    tertiary: yellow[900],
  },
  {
    primary: purple[300],
    secondary: lime[400],
    tertiary: purple[700],
  },
];

const COLORS = ['#9E95FF', '#E67E30', '#9890FE', '#85E169', '#D85F9B'];

const palette = {
  colors: { ...COLORS },
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  text: { primary: GREY[100], secondary: GREY[300], disabled: GREY[500] },
  background: { paper: '#1f1f1f', default: GREY[100], neutral: GREY[200] },
  action: {
    active: GREY[600],
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
  mode: 'dark',
};

export default palette;
