import { Box, Container, Grid } from '@mui/material';
import React from 'react';

import MainSection from './MainSection';
import SideMenuPage from './sideMenu/SideMenuPage';

type OwnProps = {};

const Main: React.FC<OwnProps> = () => {
  return (
    <Box height={1080 - 48 - 48}>
      <Grid container p={2.5} sx={{ overflow: 'hidden', h: 1080 - 48 - 48 - 40 }}>
        <Grid item xs={12} md={12} lg={2} height={1080 - 48 - 48 - 40}>
          <SideMenuPage />
        </Grid>
        <Grid container xs={12} md={12} lg={10} width={1} height={1080 - 48 - 48 - 40}>
          <MainSection />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Main;
