import styled from '@emotion/styled';
import { Box, Grid, List, ListItem, Typography } from '@mui/material';
import { blue, grey, red } from '@mui/material/colors';
import { getQuarter, getYear } from 'date-fns';
import { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { useRecoilValue } from 'recoil';
import { allReportDataAtom } from '../../../libs/states/church/church';
import { currentDtAtom, currentTermAtom, previousDtAtom } from '../../../libs/states/common';

const StyledNumber = styled(Typography)`
  font-family: 'SCoreDream';
  font-size: 27px;
  font-weight: 600;
  color: ${grey[100]};
  padding-right: 16px;
`;
const StyledSmallNumber = styled(Typography)`
  font-family: 'SCoreDream';
  font-size: 22px;
  font-weight: 600;
  color: ${grey[100]};
  padding-right: 12px;
`;
const StyledLabel = styled(Typography)`
  font-family: 'SCoreDream';
  margin-right: 16px;
  font-size: 10px;
  font-weight: 200;
  color: ${grey[500]};
`;
const StyledSmallLabel = styled(Typography)`
  font-family: 'SCoreDream';
  font-size: 8px;
  font-weight: 200;
  color: ${grey[500]};
`;

const UpText = styled(Typography)`
  font-family: 'SCoreDream';
  font-size: 12px;
  color: ${red[700]};
  /* opacity: 0.7; */
`;
const DownText = styled(Typography)`
  font-family: 'SCoreDream';
  font-size: 12px;
  color: ${blue[700]};
  /* opacity: 0.7; */
`;

const CurrentWorship = () => {
  const allReportData = useRecoilValue(allReportDataAtom);

  const currentTerm = useRecoilValue(currentTermAtom);

  const currentDt = useRecoilValue(currentDtAtom);
  const previousDt = useRecoilValue(previousDtAtom);

  const [worshipData, setWorshipData] = useState([]);
  const [regionData, setRegionData] = useState([]);

  const currentData = allReportData.filter((data) => data.dt === currentDt);
  const previousData = allReportData.filter((data) => data.dt === previousDt);

  const tueKeys = ['average_child_tue', 'average_senior_tue', 'average_student_tue'];
  const friKeys = ['average_child_fri', 'average_senior_fri', 'average_student_fri'];
  const satKeys = ['average_child_sat', 'average_senior_sat', 'average_student_sat'];
  const sat2Keys = ['average_child_sat_2', 'average_senior_sat_2', 'average_student_sat_2'];

  useEffect(() => {
    const init = () => {
      if (allReportData.length === 0) return;
      const sumCalc = (data, keys) => {
        const result = data
          .map((region) => {
            const calc = keys.reduce((acc, key) => {
              acc += region[key];
              return acc;
            }, 0);
            return calc;
          })
          .reduce((acc, cur) => acc + cur, 0);

        return result;
      };

      const sumWorshipData = [
        {
          title: '화요일',
          count: sumCalc(currentData, tueKeys),
          change: sumCalc(currentData, tueKeys) - sumCalc(previousData, tueKeys),
        },
        {
          title: '금요일',
          count: sumCalc(currentData, friKeys),
          change: sumCalc(currentData, friKeys) - sumCalc(previousData, friKeys),
        },
        {
          title: '안식일',
          count: sumCalc(currentData, satKeys),
          change: sumCalc(currentData, satKeys) - sumCalc(previousData, satKeys),
        },
        {
          title: '2부예배',
          count: sumCalc(currentData, sat2Keys),
          change: sumCalc(currentData, sat2Keys) - sumCalc(previousData, sat2Keys),
        },
      ];
      setWorshipData(sumWorshipData);
    };
    init();
  }, [allReportData]);

  useEffect(() => {
    const init = () => {
      if (allReportData.length === 0) return;
      const sumCalc = (data, keys, regionId) => {
        const filteredRegion = data.filter((region) => region['area_id'] === regionId);

        const result = keys.reduce((acc, key) => {
          acc += filteredRegion[0][key];
          return acc;
        }, 0);
        return result;
      };

      const sumRegionData = [
        {
          region: '대구경산선교지역',
          detail: [
            {
              title: '안식일',
              count: sumCalc(currentData, satKeys, 1),
              change: sumCalc(currentData, satKeys, 1) - sumCalc(previousData, satKeys, 1),
            },
            {
              title: '금요일',
              count: sumCalc(currentData, satKeys, 1),
              change: sumCalc(currentData, satKeys, 1) - sumCalc(previousData, satKeys, 1),
            },
          ],
        },
        {
          region: '경북북부선교지역',
          detail: [
            {
              title: '안식일',
              count: sumCalc(currentData, satKeys, 2),
              change: sumCalc(currentData, satKeys, 2) - sumCalc(previousData, satKeys, 2),
            },
            {
              title: '금요일',
              count: sumCalc(currentData, friKeys, 2),
              change: sumCalc(currentData, friKeys, 2) - sumCalc(previousData, friKeys, 2),
            },
          ],
        },
        {
          region: '경북중부선교지역',
          detail: [
            {
              title: '안식일',
              count: sumCalc(currentData, satKeys, 3),
              change: sumCalc(currentData, satKeys, 3) - sumCalc(previousData, satKeys, 3),
            },
            {
              title: '금요일',
              count: sumCalc(currentData, friKeys, 3),
              change: sumCalc(currentData, friKeys, 3) - sumCalc(previousData, friKeys, 3),
            },
          ],
        },
        {
          region: '경북서부선교지역',
          detail: [
            {
              title: '안식일',
              count: sumCalc(currentData, satKeys, 4),
              change: sumCalc(currentData, satKeys, 4) - sumCalc(previousData, satKeys, 4),
            },
            {
              title: '금요일',
              count: sumCalc(currentData, friKeys, 4),
              change: sumCalc(currentData, friKeys, 4) - sumCalc(previousData, friKeys, 4),
            },
          ],
        },
        {
          region: '동해안선교지역',
          detail: [
            {
              title: '안식일',
              count: sumCalc(currentData, satKeys, 5),
              change: sumCalc(currentData, satKeys, 5) - sumCalc(previousData, satKeys, 5),
            },
            {
              title: '금요일',
              count: sumCalc(currentData, friKeys, 5),
              change: sumCalc(currentData, friKeys, 5) - sumCalc(previousData, friKeys, 5),
            },
          ],
        },
        {
          region: '부산남부선교지역',
          detail: [
            {
              title: '안식일',
              count: sumCalc(currentData, satKeys, 6),
              change: sumCalc(currentData, satKeys, 6) - sumCalc(previousData, satKeys, 6),
            },
            {
              title: '금요일',
              count: sumCalc(currentData, friKeys, 6),
              change: sumCalc(currentData, friKeys, 6) - sumCalc(previousData, friKeys, 6),
            },
          ],
        },
        {
          region: '부산북부선교지역',
          detail: [
            {
              title: '안식일',
              count: sumCalc(currentData, satKeys, 7),
              change: sumCalc(currentData, satKeys, 7) - sumCalc(previousData, satKeys, 7),
            },
            {
              title: '금요일',
              count: sumCalc(currentData, friKeys, 7),
              change: sumCalc(currentData, friKeys, 7) - sumCalc(previousData, friKeys, 7),
            },
          ],
        },
        {
          region: '경남동부선교지역',
          detail: [
            {
              title: '안식일',
              count: sumCalc(currentData, satKeys, 8),
              change: sumCalc(currentData, satKeys, 8) - sumCalc(previousData, satKeys, 8),
            },
            {
              title: '금요일',
              count: sumCalc(currentData, friKeys, 8),
              change: sumCalc(currentData, friKeys, 8) - sumCalc(previousData, friKeys, 8),
            },
          ],
        },
        {
          region: '경남남부선교지역',
          detail: [
            {
              title: '안식일',
              count: sumCalc(currentData, satKeys, 9),
              change: sumCalc(currentData, satKeys, 9) - sumCalc(previousData, satKeys, 9),
            },
            {
              title: '금요일',
              count: sumCalc(currentData, friKeys, 9),
              change: sumCalc(currentData, friKeys, 9) - sumCalc(previousData, friKeys, 9),
            },
          ],
        },
        {
          region: '경남서부선교지역',
          detail: [
            {
              title: '안식일',
              count: sumCalc(currentData, satKeys, 10),
              change: sumCalc(currentData, satKeys, 10) - sumCalc(previousData, satKeys, 10),
            },
            {
              title: '금요일',
              count: sumCalc(currentData, friKeys, 10),
              change: sumCalc(currentData, friKeys, 10) - sumCalc(previousData, friKeys, 10),
            },
          ],
        },
      ];
      setRegionData(sumRegionData);
    };
    init();
  }, [allReportData]);

  return (
    <>
      <Grid mb={5} container>
        {worshipData.map((w, i) => {
          return (
            <Box key={i} sx={{ display: 'flex', width: '100%' }}>
              <Grid item lg={3} sx={{ display: 'flex', alignItems: 'center' }}>
                <StyledLabel>{w.title}</StyledLabel>
              </Grid>
              <Grid item lg={6} sx={{ display: 'flex', justifyContent: 'end' }}>
                <StyledNumber>
                  <CountUp end={w.count} separator="," duration={Math.floor(w.count / 800)} />
                </StyledNumber>
              </Grid>
              <Grid item lg={3} sx={{ display: 'flex', alignItems: 'center' }}>
                {w.change > 0 ? <UpText>▲ {w.change}</UpText> : <DownText>▼ {Math.abs(w.change)}</DownText>}
              </Grid>
            </Box>
          );
        })}
        <Grid item lg={8} />
        <Grid item lg={4}>
          <StyledLabel sx={{ color: grey[500], fontSize: 10, fontFamily: 'SCoreDream', fontWeight: 200 }}>{`${getYear(currentTerm)}년 ${getQuarter(currentTerm)}기 기준`}</StyledLabel>
        </Grid>
      </Grid>
      <Typography sx={{ fontSize: '24px', color: grey[100], fontFamily: 'SCoreDream', fontWeight: 500 }} pb={1}>
        지역별 예배현황
      </Typography>
      <List sx={{ maxHeight: 450, overflow: 'auto' }}>
        <Grid container>
          {regionData.map((r, i) => {
            return (
              <ListItem key={i} sx={{ width: '100%', p: 0 }}>
                <Box key={i} mb={2} sx={{ width: '100%' }}>
                  <Grid item lg={12} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography fontSize={16} fontFamily="SCoreDream" fontWeight={300} color={grey[400]}>
                      {r.region}
                    </Typography>
                  </Grid>
                  <Grid container sx={{ borderTop: `1px solid ${grey[500]}`, width: '100%' }}>
                    {r.detail.map((d, i) => {
                      return (
                        <Box key={i} sx={{ display: 'flex', width: '100%' }}>
                          <Grid item lg={1} />
                          <Grid item lg={3} sx={{ display: 'flex', alignItems: 'center' }}>
                            <StyledSmallLabel>{d.title}</StyledSmallLabel>
                          </Grid>
                          <Grid item lg={5} sx={{ display: 'flex', justifyContent: 'end' }}>
                            <StyledSmallNumber>
                              <CountUp end={d.count} separator="," duration={Math.floor(d.count / 300)} />
                            </StyledSmallNumber>
                          </Grid>
                          <Grid item lg={3} sx={{ display: 'flex', alignItems: 'center' }}>
                            {d.change > 0 ? <UpText>▲ {d.change}</UpText> : <DownText>▼ {Math.abs(d.change)}</DownText>}
                          </Grid>
                        </Box>
                      );
                    })}
                  </Grid>
                </Box>
              </ListItem>
            );
          })}
        </Grid>
      </List>
      <Grid container>
        <Grid item lg={8} />
        <Grid item lg={4}>
          <StyledLabel sx={{ color: grey[500], fontSize: 10, fontFamily: 'SCoreDream', fontWeight: 200 }}>{`${getYear(currentTerm)}년 ${getQuarter(currentTerm)}기 기준`}</StyledLabel>
        </Grid>
      </Grid>
    </>
  );
};

export default CurrentWorship;
