import styled from '@emotion/styled';
import { Box, Icon, List, ListItemButton, Tab, Tabs, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useState } from 'react';

const StyledIcon = styled(Icon)`
  margin-right: 20px;
  font-size: 20px;
`;
const StyledTitle = styled(Typography)`
  margin-right: 12px;
  font-size: 20px;
  font-family: 'SCoreDream';
  font-weight: 300;
`;

const AntTabs = styled(Tabs)({
  borderBottom: `1px solid ${grey[800]}`,
  fontFamily: 'SCoreDream',
  fontWeight: 300,
  fontSize: '12px',
  '& .MuiTabs-indicator': {
    backgroundColor: '#78b8cc',
  },
});

interface StyledTabProps {
  label: string;
}

const AntTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(({ theme }) => ({
  fontFamily: 'SCoreDream',
  fontWeight: 300,
  fontSize: '13px',
  '&:hover': {
    color: '#78c8cc',
    opacity: 1,
  },
  '&.Mui-selected': {
    color: '#78b8cc',
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff',
  },
}));

interface OwnProps {}

const DigitalTab: React.FC<OwnProps> = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [value, setValue] = useState(0);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Box mb={2}>
      <AntTabs textColor="primary" indicatorColor="primary" value={selectedTab} onChange={handleChange} variant="fullWidth">
        <AntTab label="숨 활성화도" />
        <AntTab label="기도 필요 지역" />
        <AntTab label="숨친 프로젝트 시행률" />
      </AntTabs>
    </Box>
  );
};

export default DigitalTab;
