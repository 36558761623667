import { Card } from '@mui/material';
import { grey } from '@mui/material/colors';

import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { chartStyle } from '../../../../libs/theme/chart';

const CarryOutAndActivate = () => {
  const chartSeries: ApexOptions['series'] = [
    {
      name: '숨 활성화도',
      type: 'bar',
      data: [23, 34, 45, 56, 67],
    },
    {
      name: '프로젝트 시행률',
      type: 'bar',
      data: [12, 23, 34, 47, 47],
    },
  ];

  const chartOptions: ApexOptions = {
    chart: {
      type: 'line',
      toolbar: { show: false },
      zoom: { enabled: false },
    },
    xaxis: {
      categories: ['4월', '5월', '6월', '7월', '8월'],

      labels: { ...chartStyle.labels },
    },
    yaxis: {
      labels: { ...chartStyle.labels },
    },
    legend: {
      position: 'top',
      offsetY: 0,
      ...chartStyle.legend,
    },
    title: {
      text: '숨 활성화도 및 프로젝트 시행률',
      style: {
        ...chartStyle.title,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 3,
        borderRadiusApplication: 'end',
      },
    },
    dataLabels: {
      enabled: false,
    },

    tooltip: {
      theme: 'dark',
    },
    colors: ['#78b8cc', '#4B7380'],

    grid: { ...chartStyle.grid },
  };

  return (
    <Card sx={{ backgroundColor: grey[900], p: 2 }}>
      <ReactApexChart options={chartOptions} series={chartSeries} type="bar" width="100%" height={241} />
    </Card>
  );
};

export default CarryOutAndActivate;
