import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';

import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

const Header = () => {
  // const { isPlay, setIsPlay } = useContext(CommonContext);
  return (
    <AppBar position="static" sx={{ backgroundColor: '#1c1c1c' }}>
      <Container maxWidth={false}>
        <Toolbar disableGutters variant="dense">
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img src="/logo192.png" alt="" style={{ width: 30, height: 30, marginRight: 8 }} />
            <Box>
              <Typography sx={{ fontSize: '1rem', fontWeight: 700, fontFamily: 'SCoreDream' }}>
                디지털 <span style={{ color: 'orange' }}>영남합회</span>
              </Typography>
              <Typography sx={{ fontSize: '0.5rem', color: '#676767', fontFamily: 'SCoreDream' }}>Youngnam Church Management System</Typography>
            </Box>
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ flexGrow: 0 }}>
            <Typography sx={{ fontSize: 10 }}>
              <IconButton color="default" aria-label="자동 넘김을 멈춥니다">
                <span className="material-icons">play_circle</span>
              </IconButton>
            </Typography>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
