import React from 'react';
import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useRecoilValue } from 'recoil';
import { selectedMenuState } from '../../../libs/states/common';

const WatchWord = () => {
  const selectedMenu = useRecoilValue(selectedMenuState);

  const colors = ['orange', 'blue', 'teal', 'yellow'];
  const watchword = '깨어라, 기도하라, 일하라';
  const watchBible = `"저희가 날마다 성전에 있든지 집에 있든지 예수는 그리스도라 가르치기와 전도하기를 쉬지 아니하니라"`;
  const bibleVerse = '(사도행전 5:42)';

  return (
    <Box mb={9} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Box component="img" src={`/common/img/logo/iwg-${colors[selectedMenu.index]}.png`} height={140} />
      <Card elevation={0} sx={{ borderRadius: 0, backgroundColor: '#191919', height: 140 }}>
        <CardContent sx={{ paddingTop: 2 }}>
          <Stack>
            <Typography sx={{ fontSize: 17, fontWeight: 600, textAlign: 'center', fontFamily: 'SCoreDream', color: grey[300] }} mb={1}>
              {watchword}
            </Typography>
            <Typography sx={{ fontSize: 14, fontWeight: 200, fontFamily: 'SCoreDream', textAlign: 'center', color: grey[300] }} mb={0.5}>
              {watchBible}
            </Typography>
            <Typography sx={{ fontSize: 12, fontWeight: 200, fontFamily: 'SCoreDream', textAlign: 'right', color: grey[500] }}>{bibleVerse}</Typography>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
};

export default WatchWord;
