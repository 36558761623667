export default class AdditionService {
    constructor(http) {
        this.http = http;
    }

    /**
     * 성장목표를 가져온다
     * @returns { goals data }
     */
    async getGoals() {
        return this.http.get(`/goals`);
    }

    /**
     * 스케줄을 가져온다.
     * @param { number } year
     * @returns { schedules data }
     */
    async getSchedules({ year }) {
        const params = year ? { year } : undefined;
        return this.http.get(`/schedules`, { params });
    }

    /**
     * 표어를 가져온다.
     * @param { number } year
     * @returns { goals data }
     */
    async get({ year }) {
        const params = year ? { year } : undefined;
        return this.http.get(`/schedules`, { params });
    }
}
