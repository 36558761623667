import React from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import { AuthErrorEventBus, AuthProvider } from './libs/contexts/AuthContext';
import { config } from './config';
import { fetchCsrfToken } from './libs/contexts/AuthContext';

import HttpClient from './libs/network/http';

import AuthService from './libs/services/AuthService';
import ReportService from './libs/services/ReportService';
import AdditionService from './libs/services/AdditionService';

import App from './App';
import './index.css';

const baseURL = config.server.cornerstone;
const authErrorEventBus = new AuthErrorEventBus();
const httpClient = new HttpClient(
  baseURL,
  authErrorEventBus, //
  () => fetchCsrfToken(),
);

const authService = new AuthService(httpClient);
const reportService = new ReportService(httpClient);
const additionService = new AdditionService(httpClient);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <RecoilRoot>
      <AuthProvider
        authService={authService} //
        authErrorEventBus={authErrorEventBus}
      >
        <App mainService={reportService} additionService={additionService} />
      </AuthProvider>
    </RecoilRoot>
  </BrowserRouter>,
);
