export const pastors = [
  { name: '김성현', church: '통영노산교회', experience: 10 },
  { name: '권혁진', church: '함양교회', experience: 10 },
  { name: '서현석', church: '통영하늘숲교회', experience: 10 },
  { name: '한사랑', church: '청송교회', experience: 10 },
  { name: '김용일', church: '성지교회', experience: 10 },
  { name: '이해건', church: '영남삼육교회', experience: 10 },
  { name: '하헌우', church: '양산교회', experience: 10 },
  { name: '최재현', church: '부산중앙교회', experience: 10 },
  { name: '서반석', church: '대구삼육초등학교교회', experience: 10 },
  { name: '정주찬', church: '대구중앙교회', experience: 10 },
  { name: '정낙훈', church: '부산서부교회', experience: 10 },
  { name: '권예준', church: '삼육부산병원교회', experience: 10 },
  { name: '장동하', church: '동래교회', experience: 10 },
  { name: '정욱재', church: '새움교회', experience: 10 },
  { name: '정재명', church: '의성서부교회', experience: 10 },
  { name: '김안식', church: 'AUP한인교회', experience: 10 },
  { name: '엄인준', church: '울진교회', experience: 10 },
  { name: '박병관', church: '진보교회', experience: 10 },
  { name: '김진수', church: '부천동교회', experience: 10 },
  { name: '박상오', church: '법전교회', experience: 10 },
  { name: '송민호', church: '부산중앙교회', experience: 10 },
  { name: '박규현', church: '부산서부교회', experience: 10 },
  { name: '김동섭', church: '영남합회', experience: 20 },
  { name: '남시창', church: '영남합회', experience: 20 },
  { name: '배순균', church: '영남합회', experience: 20 },
  { name: '오동준', church: '영남합회', experience: 20 },
  { name: '오창규', church: '영남합회', experience: 20 },
  { name: '장대기', church: '영남합회', experience: 20 },
  { name: '홍철표', church: '영남합회', experience: 20 },
  { name: '윤용일', church: '울산전원교회', experience: 20 },
  { name: '홍성호', church: '마산삼육교회', experience: 20 },
  { name: '이원오', church: '동마산교회', experience: 20 },
  { name: '정원익', church: '남지교회', experience: 20 },
  { name: '박천욱', church: '함안교회', experience: 20 },
  { name: '송병호', church: '밀양교회', experience: 20 },
  { name: '이성찬', church: '고성남산교회', experience: 20 },
  { name: '우봉옥', church: '동산교회', experience: 20 },
  { name: '최영규', church: '죽림염광교회', experience: 20 },
  { name: '정용수', church: '학산교회', experience: 20 },
  { name: '황용준', church: '삼천포한빛교회', experience: 20 },
  { name: '고상일', church: '삼천포천성교회', experience: 20 },
  { name: '김다윗', church: '사천교회', experience: 20 },
  { name: '최철종', church: '덕동교회', experience: 20 },
  { name: '이용수', church: '남해교회', experience: 20 },
  { name: '서정우', church: '진주중앙교회', experience: 20 },
  { name: '백현철', church: '덕교교회', experience: 20 },
  { name: '하홍팔', church: '소남교회', experience: 20 },
  { name: '윤세수', church: '유점교회', experience: 20 },
  { name: '조해준', church: '하동교회', experience: 20 },
  { name: '김성안', church: '구정교회', experience: 20 },
  { name: '이주안', church: '벧엘수양원교회', experience: 20 },
  { name: '정요한', church: '북천교회', experience: 20 },
  { name: '이종학', church: '황금길교회', experience: 20 },
  { name: '남상욱', church: '악양교회', experience: 20 },
  { name: '이용훈', church: '횡천교회', experience: 20 },
  { name: '황희정', church: '사랑의집교회', experience: 20 },
  { name: '유대현', church: '안의교회', experience: 20 },
  { name: '최인봉', church: '화산리교회', experience: 20 },
  { name: '이병주', church: '새울산교회', experience: 20 },
  { name: '백상봉', church: '진주솔밭교회', experience: 20 },
  { name: '이헌', church: '진교열린교회', experience: 20 },
  { name: '석종식', church: '왜관교회', experience: 20 },
  { name: '임순극', church: '용포교회', experience: 20 },
  { name: '이성후', church: '용화교회', experience: 20 },
  { name: '박광선', church: '점촌하늘빛교회', experience: 20 },
  { name: '이승동', church: '청리좋은교회', experience: 20 },
  { name: '김종환', church: '함창교회', experience: 20 },
  { name: '정승호', church: '영주교회', experience: 20 },
  { name: '김창규', church: '대평교회', experience: 20 },
  { name: '최충기', church: '부석교회', experience: 20 },
  { name: '이수명', church: '도심교회', experience: 20 },
  { name: '최종섭', church: '두음교회', experience: 20 },
  { name: '김은상', church: '삼육봉화교회', experience: 20 },
  { name: '권재선', church: '물야교회', experience: 20 },
  { name: '서정열', church: '재산교회', experience: 20 },
  { name: '조중근', church: '죽변하늘소망교회', experience: 20 },
  { name: '윤용균', church: '후포교회', experience: 20 },
  { name: '정현수', church: '안동교회', experience: 20 },
  { name: '박권수', church: '예천하늘빛교회', experience: 20 },
  { name: '강상진', church: '예천변화산교회', experience: 20 },
  { name: '이동탁', church: '일직교회', experience: 20 },
  { name: '정범진', church: '풍산교회', experience: 20 },
  { name: '박채문', church: '덕성교회', experience: 20 },
  { name: '함영만', church: '영양교회', experience: 20 },
  { name: '곽용언', church: '의성교회', experience: 20 },
  { name: '강종욱', church: '용연교회', experience: 20 },
  { name: '강명길', church: '점곡교회', experience: 20 },
  { name: '김홍', church: '팔성교회', experience: 20 },
  { name: '손영모', church: '단밀교회', experience: 20 },
  { name: '정철진', church: '군위교회', experience: 20 },
  { name: '박형모', church: '의성서부교회', experience: 20 },
  { name: '조철현', church: '경주교회', experience: 20 },
  { name: '박상철', church: '포항교회', experience: 20 },
  { name: '김성일', church: '영덕사랑교회', experience: 20 },
  { name: '김종일', church: '울릉주민교회', experience: 20 },
  { name: '진기영', church: '포항오천교회', experience: 20 },
  { name: '최철순', church: '가산교회', experience: 20 },
  { name: '김원성', church: '강북교회', experience: 20 },
  { name: '김형철', church: '대구서부교회', experience: 20 },
  { name: '최윤석', church: '동대구새빛교회', experience: 20 },
  { name: '김명오', church: '복현교회', experience: 20 },
  { name: '박정근', church: '대구국제교회', experience: 20 },
  { name: '권성관', church: '경산중앙교회', experience: 20 },
  { name: '강정웅', church: '임마누엘교회', experience: 20 },
  { name: '이주현', church: '영남삼육교회', experience: 20 },
  { name: '김종수', church: '진량교회', experience: 20 },
  { name: '이덕신', church: '청도교회', experience: 20 },
  { name: '김영한', church: '삼육청도연수원교회', experience: 20 },
  { name: '류현두', church: '영천강변교회', experience: 20 },
  { name: '박찬영', church: '고령전원교회', experience: 20 },
  { name: '이정헌', church: '삼육현풍교회', experience: 20 },
  { name: '최기웅', church: '동래교회', experience: 20 },
  { name: '송바다', church: '기장유리바다교회', experience: 20 },
  { name: '이성일', church: '반송교회', experience: 20 },
  { name: '김윤상', church: '해운대교회', experience: 20 },
  { name: '이동열', church: '부산삼육교회', experience: 20 },
  { name: '유석근', church: '달맞이요양병원교회', experience: 20 },
  { name: '김성진', church: '서면교회', experience: 20 },
  { name: '최금혁', church: '광안교회', experience: 20 },
  { name: '우윤구', church: '부산국제교회', experience: 20 },
  { name: '권영걸', church: '용호교회', experience: 20 },
  { name: '김수창', church: '부산메트로교회', experience: 20 },
  { name: '류재성', church: '부산서부교회', experience: 20 },
  { name: '황일근', church: '가덕교회', experience: 20 },
  { name: '김민', church: '덕천교회', experience: 20 },
  { name: '한민규', church: '새부산교회', experience: 20 },
  { name: '조봉현', church: '김해교회', experience: 20 },
  { name: '손동웅', church: '장유교회', experience: 20 },
  { name: '김재림', church: '김해사랑나눔교회', experience: 20 },
  { name: '원종인', church: '사하교회', experience: 20 },
  { name: '윤영진', church: '삼육영도교회', experience: 20 },
  { name: '김성조', church: '새소망교회', experience: 20 },
  { name: '김성조', church: '새소망교회', experience: 20 },
  { name: '강정웅', church: '임마누엘교회', experience: 20 },
  { name: '김성수', church: '영주남부교회', experience: 20 },
  { name: '박정욱', church: '동울산교회', experience: 20 },
  { name: '윤정민', church: '울산시온교회', experience: 20 },
  { name: '한동철', church: '울산서부교회', experience: 20 },
  { name: '김종화', church: '통영천문교회', experience: 20 },
  { name: '최철순', church: '대구중앙교회', experience: 20 },
  { name: '김태수', church: '울산중앙교회', experience: 20 },
  { name: '조현제', church: '거제하늘빛교회', experience: 20 },
  { name: '강하식', church: '구미교회', experience: 20 },
  { name: '권성열', church: '장림교회', experience: 20 },
  { name: '김동열', church: '대구성서교회', experience: 20 },
  { name: '변영기', church: '중동교회', experience: 20 },
  { name: '배영동', church: '거창교회', experience: 20 },
  { name: '서장원', church: '풍기교회', experience: 20 },
  { name: '신현철', church: '상주교회', experience: 20 },
  { name: '우평수', church: '대구중앙교회', experience: 20 },
  { name: '장건영', church: '진주중앙교회', experience: 20 },
  { name: '조준일', church: '삼랑진교회', experience: 20 },
  { name: '최성일', church: '춘양교회', experience: 20 },
  { name: '함대식', church: '창원새하늘교회', experience: 20 },
  { name: '남성실', church: '문경교회', experience: 20 },
  { name: '오상은', church: '옥산교회', experience: 20 },
  { name: '안태근', church: '꽃내쉼터교회', experience: 20 },
  { name: '김종문', church: '서벽교회', experience: 20 },
  { name: '우경동', church: '알파시티교회', experience: 20 },
  { name: '오호철', church: '삼천포한빛교회', experience: 20 },
  { name: '정길주', church: '센텀교회', experience: 20 },
  { name: '서경신', church: '진해교회', experience: 30 },
  { name: '장영태', church: '센텀교회', experience: 30 },
  { name: '조수철', church: '금정교회', experience: 30 },
  { name: '권영섭', church: '김천교회', experience: 30 },
  { name: '배혜주', church: '합천삼육교회', experience: 30 },
  { name: '박상호', church: '상주서부교회', experience: 30 },
  { name: '김승국', church: '부산중앙교회', experience: 30 },
];
