import { Card } from '@mui/material';
import { grey, orange } from '@mui/material/colors';

import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { chartStyle } from '../../../../libs/theme/chart';

const Attendance = () => {
  const chartSeries: ApexOptions['series'] = [
    {
      name: '화요일',
      type: 'bar',
      data: [2025, 2034, 2045, 2056, 2067],
    },
    {
      name: '금요일',
      type: 'bar',
      data: [2412, 2423, 2434, 2445, 2456],
    },
    {
      name: '안식일',
      type: 'bar',
      data: [4988, 5000, 5022, 5044, 5066],
    },
  ];

  const chartOptions: ApexOptions = {
    chart: {
      type: 'bar',
      toolbar: { show: false },
      zoom: { enabled: false },
    },
    stroke: {
      curve: 'smooth',
      width: [0, 0, 0],
    },
    xaxis: {
      categories: ['2023년 1기', '2기', '3기', '4기', '2024년 1기'],

      labels: { ...chartStyle.labels },
    },
    yaxis: [
      {
        labels: { ...chartStyle.labels },
      },
    ],

    legend: {
      position: 'top',
      offsetY: 0,
      ...chartStyle.legend,
    },
    title: {
      text: '출석 추이',
      style: {
        ...chartStyle.title,
      },
    },
    plotOptions: {
      // bar: {
      //   horizontal: false,
      //   borderRadius: 3,
      //   dataLabels: {
      //     total: {
      //       enabled: true,
      //       style: {
      //         fontSize: '10',
      //         fontFamily: 'SCoreDream',
      //         color: grey[300],
      //       },
      //     },
      //   },
      // },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      theme: 'dark',
    },
    colors: [orange[200], orange[400], orange[700]],
    grid: { ...chartStyle.grid },
  };

  return (
    <Card sx={{ backgroundColor: grey[900], p: 2, mb: 2 }}>
      <ReactApexChart options={chartOptions} series={chartSeries} type="bar" width="100%" height={241} />
    </Card>
  );
};

export default Attendance;
