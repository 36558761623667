export default class ReportService {
    constructor(http) {
        this.http = http;
    }

    /**
     * 교회 id로 분기별 기말보고서 데이터를 가져온다.
     * @param {number} churchId
     * @returns { report data }
     */
    async getQuarterReportDataByChurchId({ churchId }) {
        const params = churchId ? { churchId } : undefined;
        return this.http.get(`/reports/churches/${churchId}/quarterly`, { params });
    }

    /**
     * 교회 id로 분기별 출석데이터를 가져온다.
     * @param {number} churchId
     * @returns { attendance data }
     */
    async getQuarterAttendanceByChurchId({ churchId }) {
        const params = churchId ? { churchId } : undefined;
        return this.http.get(`/reports/churches/${churchId}/quarterly/attendance`, { params });
    }

    /**
     * 교회 id로 분기별 성장데이터를 가져온다.
     * @param {number} churchId
     * @returns { growth data }
     */
    async getQuarterGrowthByChurchId({ churchId }) {
        const params = churchId ? { churchId } : undefined;
        return this.http.get(`/reports/churches/${churchId}/quarterly/growth`, { params });
    }

    /**
     * 교회 id로 연별 출석데이터를 가져온다.
     * @param {number} churchId
     * @returns { attendance data }
     */
    async getYearAttendanceByChurchId({ churchId }) {
        const params = churchId ? { churchId } : undefined;
        return this.http.get(`/reports/churches/${churchId}/yearly/attendance`, { params });
    }

    /**
     * 교회 id로 연별 성장데이터를 가져온다.
     * @param {number} churchId
     * @returns { growth data }
     */
    async getYearGrowthByChurchId({ churchId }) {
        const params = churchId ? { churchId } : undefined;
        return this.http.get(`/reports/churches/${churchId}/yearly/growth`, { params });
    }

    /**
     * 교회 id로 분기별 연령데이터를 가져온다.
     * @param {number} churchId
     * @returns { age group data }
     */
    async getQuarterAgeGroupByChurchId({ churchId }) {
        const params = churchId ? { churchId } : undefined;
        return this.http.get(`/reports/churches/${churchId}/quarterly/members/age-group`, { params });
    }

    /**
     * 전체 기말보고서 데이터를 가져온다.
     * @returns { report data }
     */
    async getAllQuarterReportData() {
        const params = undefined;
        return this.http.get(`/reports/summary/quarterly`, { params });
    }
    /**
     * 전체 기말보고서 데이터를 가져온다.
     * @returns { report data }
     */
    async getYearBaptismData() {
        const params = undefined;
        return this.http.get(`/reports/summary/yearly/baptist`, { params });
    }

    /**
     * 전체 교회리스트 데이터를 가져온다.
     * @returns { churches data }
     */
    async getChurchesList() {
        const params = undefined;
        return this.http.get(`/churches`, { params });
    }

    /**
     * 전체 일정을 가져온다.
     * @returns { schedules data }
     */
    async getSchedules() {
        const params = undefined;
        return this.http.get('/schedules', { params });
    }

    /**
     * 전체 목표를 가져온다.
     * @returns { goals data }
     */
    async getGoals() {
        const params = undefined;
        return this.http.get('/goals', { params });
    }

    /**
     * 목회자 정보를 가져온다.
     * @param {number} pastorId
     * @returns { pastor info}
     */
    async getPastorInfo({ pastorId }) {
        const params = undefined;
        return this.http.get(`/pastors/${pastorId}`, { params });
    }

    /**
     * 목회자 정보를 가져온다.
     * @param {number} churchId
     * @returns { church info}
     */
    async getChurchInfo({ churchId }) {
        const params = undefined;
        return this.http.get(`/churches/${churchId}`, { params });
    }

    //   /**
    //    * post로 호출 할 때는 이렇게 작성한다.
    //    * : data 객체에 담아서 보낸다.
    //    */
    //   async postFunction(text) {
    //     return this.http.post(`/api/url`, {
    //       data: { text },
    //     });
    //   }

    //   async deleteFunction(textId) {
    //     return this.http.delete(`/api/url/${textId}`);
    //   }

    //   /**
    //    * post와 동일하게 data 객체에 담아서 보내거나 오브젝트 객체 형태로 보낸다.
    //    */
    //   async updateFunction(textId, text) {
    //     return this.http.put(`/api/url${textId}`, {
    //       data: { text },
    //     });
    //   }
}
