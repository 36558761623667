import { Box, Grid } from '@mui/material';
import React from 'react';
import TitleBox from '../../modules/TitleBox';
import PrayerMap from './DigitalMap';

import { TITLE_BOX_HEIGHT } from '../../../libs/config/constants';
import DigitalTab from './DigitalTab';
import DigitalStatus from './DigitalStatus';
import DigitalSidePage from './DigitalSidePage';

import DigitalLegend from './DigitalLegend';

interface OwnProps {}

const PrayerPage: React.FC<OwnProps> = () => {
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={3}>
          <TitleBox />
          <Box mt={TITLE_BOX_HEIGHT + 5}>
            <DigitalStatus />
          </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Box sx={{ position: 'relative' }}>
            <DigitalTab />
            <Box sx={{ display: 'flex', justifyContent: 'space-around', mb: 2 }}>
              <PrayerMap />
            </Box>
            <DigitalLegend />
          </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={3}>
          <DigitalSidePage />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PrayerPage;
