import React from 'react';
import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { grey, orange } from '@mui/material/colors';
import { useRecoilValue } from 'recoil';
import { selectedMenuState } from '../../../libs/states/common';

const StyledNumber = styled(Typography)`
  font-family: 'SCoreDream';
  padding-top: 16px;
  margin-right: 4px;
  font-size: 25px;
  font-weight: 600;
  color: ${grey[100]};
  text-align: 'center';
`;
const StyledLabel = styled(Typography)`
  font-family: 'SCoreDream';
  padding-bottom: 16px;
  font-size: 13px;
  font-weight: 200;
  color: ${grey[500]};
`;

const StyledUnit = styled.span`
  font-family: 'SCoreDream';
  font-size: 12px;
  font-weight: 600;
  color: ${grey[100]};
`;

interface OwnProps {
  number: string;
  unit: string;
  label: string;
}

const VisionCard: React.FC<OwnProps> = ({ number, unit, label }) => {
  const selectedMenu = useRecoilValue(selectedMenuState);

  return (
    <Box
      mb={1}
      sx={{
        width: '48.5%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        border: `1px solid ${grey[800]}`,
        borderTop: `2px solid ${selectedMenu.color?.primary}`,
        backgroundColor: grey[900],
        height: 145,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'end', pb: 1 }}>
        <StyledNumber>
          <span style={{ marginRight: 4 }}>{number}</span>
          <StyledUnit>{unit}</StyledUnit>
        </StyledNumber>
      </Box>
      <StyledLabel variant="subtitle2">{label}</StyledLabel>
    </Box>
  );
};

export default VisionCard;
