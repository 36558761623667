import axios from 'axios';

export default class HttpClient {
  constructor(baseURL, authErrorEventBus, getCsrfToken) {
    this.client = axios.create({
      baseURL,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        '_csrf-token': getCsrfToken(),
      },
    });
    this.authErrorEventBus = authErrorEventBus;
  }

  async get(url, options = {}) {
    return this._request('get', url, options);
  }

  async post(url, options = {}) {
    return this._request('post', url, options);
  }

  async put(url, options = {}) {
    return this._request('put', url, options);
  }

  async delete(url, options = {}) {
    return this._request('delete', url, options);
  }

  async _request(method, url, options) {
    let response;
    try {
      response = await this.client[method](url, options);
    } catch (error) {
      console.error(error);
      if (error.response && error.response.status === 401) {
        this.authErrorEventBus.notify(error);
        return;
      }
      throw error;
    }

    return response.data;
  }
}