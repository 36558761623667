import { Region } from "../types/region";

export const region: Region = {
  sbs: {
    name: '부산남부선교지역',
  },
  nbs: {
    name: '부산북부선교지역',
  },
  dk: {
    name: '대구경산선교지역',
  },
  wkn: {
    name: '경남서부선교지역',
  },
  ekn: {
    name: '경남동부선교지역',
  },
  skn: {
    name: '경남남부선교지역',
  },
  es: {
    name: '동해안선교지역',
  },
  nkb: {
    name: '경북북부선교지역',
  },
  wkb: {
    name: '경북서부선교지역',
  },
  mkb: {
    name: '경북중부선교지역',
  },
}