import { orange } from '@mui/material/colors';

export const locations = [
    {
        churches: ['죽변하늘소망교회', '울진교회'],
        churchIds: [5200, 5199],
        top: 100,
        left: 390,
        region: 'NK',
        color: orange[100],
    },
    {
        churches: ['두음교회', '재산교회', '부천동교회', '마라뉴스타트교회'],
        churchIds: [5190, 5198, 5191, 5224],
        top: 140,
        left: 320,
        region: 'NK',
        color: orange[100],
    },
    {
        churches: ['물야교회', '법전교회', '삼육봉화교회', '봉성중앙교회', '도심교회', '춘양교회', '서벽교회', '꽃내쉼터교회'],
        churchIds: [5195, 5193, 5194, 5197, 5189, 5188, 5192, 6143],
        top: 125,
        left: 280,
        region: 'NK',
        color: orange[100],
    },
    {
        churches: ['두문교회', '부석교회', '동촌교회', '풍기교회', '영주교회', '대평교회', '영주남부교회'],
        churchIds: [5196, 5186, 5185, 5187, 5183, 5184, 6140],
        top: 145,
        left: 245,
        region: 'NK',
        color: orange[100],
    },
    {
        churches: ['풍산교회', '일직교회', '안동교회'],
        churchIds: [5206, 5205, 5202],
        top: 225,
        left: 250,
        region: 'NK',
        color: orange[100],
    },
    {
        churches: ['점곡교회', '군위교회', '팔성교회', '용연교회', '의성교회'],
        churchIds: [5213, 5216, 5214, 5212, 5211],
        top: 275,
        left: 255,
        region: 'NK',
        color: orange[100],
    },

    {
        churches: ['의성서부교회', '단밀교회'],
        churchIds: [5217, 5215],
        top: 265,
        left: 205,
        region: 'NK',
        color: orange[100],
    },
    {
        churches: ['예천변화산교회', '예천하늘빛교회', '새움교회'],
        churchIds: [5204, 5203, 6141],
        top: 190,
        left: 205,
        region: 'NK',
        color: orange[100],
    },
    {
        churches: ['구미교회', '왜관교회', '가산교회'],
        churchIds: [5172, 5173, 5227],
        top: 340,
        left: 192,
        region: 'NK',
        color: orange[100],
    },
    {
        churches: ['청송교회', '진보교회'],
        churchIds: [5207, 5210],
        top: 240,
        left: 330,
        region: 'NK',
        color: orange[100],
    },
    {
        churches: ['상주교회', '상주서부교회', '청리좋은교회', '상주휴양원교회', '용포교회'],
        churchIds: [5174, 5175, 5181, 5283, 5178],
        top: 265,
        left: 160,
        region: 'NK',
        color: orange[100],
    },
    {
        churches: ['점촌하늘빛교회', '함창교회'],
        churchIds: [5180, 5182],
        top: 210,
        left: 165,
        region: 'NK',
        color: orange[100],
    },
    {
        churches: ['포항교회', '안강교회', '포항오천교회'],
        churchIds: [5220, 5219, 5223],
        top: 350,
        left: 390,
        region: 'NK',
        color: orange[100],
    },
    {
        churches: ['울산중앙교회', '울산시온교회', '동울산교회', '새울산교회'],
        churchIds: [5118, 5120, 5119, 5164],
        top: 460,
        left: 395,
        region: 'SK',
        color: orange[400],
    },
    {
        churches: ['울산서부교회', '울산전원교회'],
        churchIds: [5121, 5122],
        top: 430,
        left: 340,
        region: 'SK',
        color: orange[400],
    },
    {
        churches: ['청도교회', '삼육청도연수원교회'],
        churchIds: [5244, 5245],
        top: 440,
        left: 280,
        region: 'TK',
        color: orange[700],
    },
    {
        churches: ['밀양교회', '삼랑진교회'],
        churchIds: [5129, 5130],
        top: 480,
        left: 295,
        region: 'SK',
        color: orange[400],
    },
    {
        churches: ['함양교회', '거창교회', '안의교회', '화산리교회'],
        churchIds: [5163, 5159, 5161, 5162],
        top: 450,
        left: 105,
        region: 'SK',
        color: orange[400],
    },
    {
        churches: ['덕교교회', '소남교회', '옥종교회', '유점교회'],
        churchIds: [5147, 5148, 5156, 5149],
        top: 530,
        left: 105,
        region: 'SK',
        color: orange[400],
    },
    {
        churches: ['사랑의집교회', '악양교회', '하동교회', '황금길교회', '횡천교회', '북천교회', '구정교회', '벧엘수양원', '진교열린교회'],
        churchIds: [5158, 5155, 5150, 5154, 5157, 5153, 5151, 5152, 5168],
        top: 565,
        left: 90,
        region: 'SK',
        color: orange[400],
    },
    {
        churches: ['진주솔밭교회', '진주중앙교회', '사천교회'],
        churchIds: [5165, 5145, 5141],
        top: 550,
        left: 145,
        region: 'SK',
        color: orange[400],
    },
    {
        churches: ['삼천포한빛교회', '삼천포천성교회'],
        churchIds: [5139, 5140],
        top: 595,
        left: 145,
        region: 'SK',
        color: orange[400],
    },
    {
        churches: ['고성남산교회', '동산교회'],
        churchIds: [5131, 5132],
        top: 590,
        left: 180,
        region: 'SK',
        color: orange[400],
    },
    {
        churches: ['돈지교회', '덕동교회'],
        churchIds: [5143, 5142],
        top: 620,
        left: 170,
        region: 'SK',
        color: orange[400],
    },
    {
        churches: ['통영노산교회', '통영하늘숲교회', '죽림염광교회', '벧엘교회', '통영천문교회', '학산교회'],
        churchIds: [5135, 5170, 5136, 5134, 5133, 5138],
        top: 610,
        left: 210,
        region: 'SK',
        color: orange[400],
    },
    {
        churches: ['거제하늘빛교회', '새고현교회', '거제교회'],
        churchIds: [5117, 5137, 5169],
        top: 615,
        left: 250,
        region: 'SK',
        color: orange[400],
    },
    {
        churches: ['창원새하늘교회', '진해교회', '동마산교회', '마산삼육교회'],
        churchIds: [5218, 5127, 5124, 5123],
        top: 535,
        left: 255,
        region: 'SK',
        color: orange[400],
    },
    {
        churches: ['함안브니엘교회', '남지교회'],
        churchIds: [5167, 5125],
        top: 515,
        left: 215,
        region: 'SK',
        color: orange[400],
    },
    {
        churches: ['김해교회', '김해사랑나눔교회', '장유교회'],
        churchIds: [5272, 5274, 5273],
        top: 530,
        left: 300,
        region: 'BS',
        color: orange[900],
    },
    {
        churches: ['진량교회', '영남삼육교회'],
        churchIds: [5243, 5242],
        top: 390,
        left: 295,
        region: 'TK',
        color: orange[700],
    },
    {
        churches: ['알파시티교회', '경산중앙교회', '임마누엘교회'],
        churchIds: [6139, 5239, 5241],
        top: 400,
        left: 270,
        region: 'TK',
        color: orange[700],
    },
    {
        churches: ['대구예닮교회', '강북교회'],
        churchIds: [5229, 5228],
        top: 365,
        left: 235,
        region: 'TK',
        color: orange[700],
    },
    {
        churches: ['대구서부교회', '대구성서교회', '현풍사랑교회'],
        churchIds: [5230, 5231, 5249],
        top: 395,
        left: 230,
        region: 'TK',
        color: orange[700],
    },
    {
        churches: ['동대구새빛교회', '대구중앙교회', '중동교회', '대구동부교회', '복현교회', '시지새하늘교회', '대구국제교회', '대구삼육초등학교교회'],
        churchIds: [5233, 5226, 5236, 5234, 5235, 5237, 5238, 6142],
        top: 385,
        left: 255,
        region: 'TK',
        color: orange[700],
    },
    {
        churches: ['부산중앙교회', '부산서부교회', '장림교회', '사하교회', '삼육부산병원교회', '새소망교회', '삼육영도교회'],
        churchIds: [5275, 5266, 5270, 5277, 5276, 5280, 5278],
        top: 565,
        left: 325,
        region: 'BS',
        color: orange[900],
    },
    {
        churches: ['양산교회', '부산삼육교회', '금정교회'],
        churchIds: [5256, 5257, 5252],
        top: 500,
        left: 335,
        region: 'BS',
        color: orange[900],
    },
    {
        churches: ['동래교회', '반송교회', '센텀교회', '기장유리바다교회', '해운대교회', '광안교회', '달맞이요양병원교회'],
        churchIds: [5250, 5254, 5251, 5253, 5260, 5255, 5258],
        top: 530,
        left: 365,
        region: 'BS',
        color: orange[900],
    },
    {
        churches: ['서면교회', '부산국제교회', '당감교회', '용호교회', '부산메트로교회'],
        churchIds: [5259, 5263, 5261, 5264, 5265],
        top: 545,
        left: 345,
        region: 'BS',
        color: orange[900],
    },
    {
        churches: ['덕천교회', '새부산교회'],
        churchIds: [5268, 5269],
        top: 535,
        left: 325,
        region: 'BS',
        color: orange[900],
    },
    {
        churches: ['옹천교회'],
        churchIds: [5225],
        region: 'NK',
        color: orange[100],
        top: 180,
        left: 260,
    },
    {
        churches: ['옥산교회'],
        churchIds: [5177],
        region: 'NK',
        color: orange[100],
        top: 280,
        left: 145,
    },
    {
        churches: ['문경교회'],
        churchIds: [5176],
        region: 'NK',
        color: orange[100],
        top: 180,
        left: 145,
    },
    {
        churches: ['용화교회'],
        churchIds: [5179],
        region: 'NK',
        color: orange[100],
        top: 205,
        left: 100,
    },
    {
        churches: ['김천교회'],
        churchIds: [5171],
        region: 'NK',
        color: orange[100],
        top: 315,
        left: 150,
    },
    {
        churches: ['영양교회'],
        churchIds: [5209],
        region: 'NK',
        color: orange[100],
        top: 180,
        left: 340,
    },
    {
        churches: ['후포교회'],
        churchIds: [5201],
        region: 'NK',
        color: orange[100],
        top: 180,
        left: 402,
    },
    {
        churches: ['영덕사랑교회'],
        churchIds: [5221],
        region: 'NK',
        color: orange[100],
        top: 250,
        left: 390,
    },
    {
        churches: ['덕성교회'],
        churchIds: [5208],
        region: 'NK',
        color: orange[100],
        top: 280,
        left: 310,
    },
    {
        churches: ['영천강변교회'],
        churchIds: [5246],
        region: 'TK',
        color: orange[700],
        top: 340,
        left: 310,
    },
    {
        churches: ['경주교회'],
        churchIds: [5218],
        region: 'NK',
        color: orange[100],
        top: 375,
        left: 365,
    },
    {
        churches: ['성지교회'],
        churchIds: [5232],
        region: 'TK',
        color: orange[700],
        top: 375,
        left: 185,
    },
    {
        churches: ['고령전원교회'],
        churchIds: [5248],
        region: 'TK',
        color: orange[700],
        top: 405,
        left: 180,
    },
    {
        churches: ['합천삼육교회'],
        churchIds: [5160],
        region: 'SK',
        color: orange[400],
        top: 445,
        left: 160,
    },
    {
        churches: ['현풍교회'],
        churchIds: [5247],
        region: 'TK',
        color: orange[700],
        top: 415,
        left: 220,
    },
    {
        churches: ['의령초대교회'],
        churchIds: [5166],
        region: 'SK',
        color: orange[400],
        top: 505,
        left: 180,
    },
    {
        churches: ['남해교회'],
        churchIds: [5144],
        region: 'SK',
        color: orange[400],
        top: 618,
        left: 112,
    },
    {
        churches: ['울릉주민교회'],
        churchIds: [5222],
        region: 'NK',
        color: orange[100],
        top: 85,
        left: 455,
    },
    {
        churches: ['가덕교회'],
        churchIds: [5267],
        region: 'BS',
        color: orange[900],
        top: 585,
        left: 295,
    },
];
